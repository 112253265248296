import React, { useEffect, useState } from "react";
import useCartManager from "../../../../../hooks/cart/useCartManager";
import Cart from "../../../../container/cart/Cart";

const HEADER_HEIGHT = 48;
const FOOTER_HEIGHT = 0;

export default function CartModal({ onClose, cart }) {
  const [states, setStates] = useState({});
  const { constructCart } = useCartManager();

  useEffect(() => {
    onInitialLoad();

    // eslint-disable-next-line
  }, [cart]);

  const onInitialLoad = async () => {
    try {
      const constructedCart = await constructCart(cart);

      setStates((prev) => ({
        ...prev,
        ...constructedCart,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log("Current states:", states);
  }, [states]);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="z-10 bg-white w-[28rem] flex-col max-w-full fixed bottom-0 right-0 top-0"
    >
      <div
        style={{
          height: HEADER_HEIGHT,
        }}
        className=" bg-blue-200 p-4 flex justify-between items-center"
      >
        <h2 className="text-lg font-semibold">Cart</h2>
        <button onClick={onClose} className="text-red-500">
          X
        </button>
      </div>

      <div
        className=" flex-col w-full "
        style={{
          height: `calc(100% - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
        }}
      >
        <Cart {...{ cart }} />
      </div>

      <div
        style={{
          height: FOOTER_HEIGHT,
        }}
        className=" bg-blue-200 p-4 flex justify-between items-center"
      ></div>
    </div>
  );
}
