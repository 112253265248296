import React, { memo } from "react";
import SavingsStrip from "./SavingsStrip";
import useCartManager from "../../../../hooks/cart/useCartManager";

const BillSummary = ({ cart }) => {
  const { calculateAdvancedCartPricing } = useCartManager();

  const cartPricing = calculateAdvancedCartPricing(cart);

  return (
    <div className="w-full bg-white rounded-xl border p-4 shadow-sm ">
      <div className="font-[600] text-[1rem] ">Bill Summary</div>

      <div className="flex flex-col  gap-2 mt-2 mb-3 text-[.825rem]   ">
        <div className="flex justify-between items-center ">
          <span>Base value</span>
          <div className="flex items-center gap-2">
            <span className="line-through text-[grey]">
              ₹{cartPricing?.productCostPrice}
            </span>
            <span className="font-[500]">
              ₹{cartPricing?.productSellingPrice}
            </span>
          </div>
        </div>

        <div className="flex justify-between items-center ">
          <span>Add-ons</span>
          <div className="flex items-center gap-2">
            {cartPricing?.addonsCostPrice > 0 && (
              <span className="line-through text-[grey]">
                ₹{cartPricing?.addonsCostPrice}
              </span>
            )}

            <span className="font-[500]">
              ₹{cartPricing?.addonsSellingPrice}
            </span>
          </div>
        </div>

        {/* {totalDiscount > 0 && ( */}
        <div className="flex justify-between items-center ">
          <div className="flex flex-col ">
            <span>Coupon saving</span>
            {cartPricing.couponDiscount > 0 && (
              <span className="text-[.625rem] border px-1 rounded-md  bg-[#F0F5FE] text-[#2136d4]">
                {cartPricing?.offerCode} applied
              </span>
            )}
          </div>
          {cartPricing.couponDiscount > 0 && (
            <span className="font-[500] text-[#2136d4]">
              - ₹{cartPricing.couponDiscount}
            </span>
          )}
        </div>

        <div className="flex justify-between items-center ">
          <span>Convenience fee</span>

          <div className="flex items-center gap-2">
            <span className="font-[500]">
              {cartPricing.convenienceFee > 0 && "+"} ₹
              {cartPricing.convenienceFee}
            </span>
          </div>
        </div>

        <div className="flex justify-between items-center ">
          <span>Santa tip</span>
          <div className="flex items-center gap-2">
            <span className="font-[500]">
              {cartPricing.santaTip > 0 && "+"} ₹{cartPricing.santaTip}
            </span>
          </div>
        </div>
        <div className="font-[800] border-t border-dashed  text-[1rem] flex items-center justify-between mt-2 pt-4">
          <span>Total</span>
          <span>₹{cartPricing.amountToPay}</span>
        </div>
      </div>
      <SavingsStrip cart={cart} />
    </div>
  );
};

export default memo(BillSummary);
