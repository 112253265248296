import React from "react";
import { FaMapMarkerAlt, FaEdit } from "react-icons/fa";

export default function AreaField({ states, setStates }) {
  const { address } = states;

  return (
    <div className="space-y-2">
      {/* Label */}
      <label className="block text-xs font-semibold text-gray-600">
        Area & Address
      </label>

      {/* Manual Area Input */}
      <div className="relative">
        <input
          type="text"
          className="border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md px-3 py-2 text-sm w-full shadow-sm transition"
          placeholder="E.g. Thane, Church gate, etc."
          value={states.area || ""}
          onChange={(e) =>
            setStates((prev) => ({ ...prev, area: e.target.value }))
          }
        />
      </div>

      {/* Address Section (Addon Feature) */}
      {address.address1 ? (
        <div className="border border-gray-300 rounded-lg shadow-sm p-3 flex items-start justify-between bg-gray-50">
          <div>
            <p className="text-sm font-semibold text-gray-800">
              {address.address1}
            </p>
            {address.address2 && (
              <p className="text-xs text-gray-600">{address.address2}</p>
            )}
            <span className="inline-block text-2xs mt-1 px-2 py-1 bg-blue-100 text-blue-700 rounded">
              {address.city}
            </span>
          </div>
          <button
            onClick={() =>
              setStates((prev) => ({
                ...prev,
                isAddressSelectionModalOpen: true,
              }))
            }
            className="text-blue-600 hover:text-blue-800 transition flex items-center gap-1 text-xs"
          >
            <FaEdit size={12} />
            Change
          </button>
        </div>
      ) : (
        // If no address is selected, show the "Select Address" button
        <button
          onClick={() =>
            setStates((prev) => ({
              ...prev,
              isAddressSelectionModalOpen: true,
            }))
          }
          className="w-full flex items-center justify-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition"
        >
          <FaMapMarkerAlt />
          Select Address
        </button>
      )}
    </div>
  );
}
