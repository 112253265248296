import React from "react";
import { FaCalendarAlt } from "react-icons/fa";

export default function CelebrationDateField({ states, setStates }) {
  const handleDateChange = (e) => {
    setStates((prev) => ({ ...prev, celebrationDate: e.target.value }));
  };

  return (
    <div className="relative flex-1">
      <FaCalendarAlt className="absolute left-3 top-3 text-gray-500 text-sm" />
      <input
        type="date"
        className="border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md px-8 py-2 text-sm w-full shadow-sm transition"
        value={states.celebrationDate || ""}
        onChange={handleDateChange}
      />
    </div>
  );
}
