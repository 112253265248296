import React, { useState, useEffect } from "react";
import BottomButtons from "./subC/BottomButtons";
import Header from "./subC/Header";
import UserSelectionContainer from "./subC/UserSelectionContainer";
import AddressSelectorSection from "./subC/AddressSelectorSection";
import TimeSlotSection from "./subC/TimeSlotSection";
import ProductSelection from "./subC/ProductSelection";
import BillSection from "./subC/BillSection";
import {
  calculate_addons_price,
  calculate_offer_discount,
} from "./subC/placeManualOrder";
import PersonalInfoEdit from "../../userComponents/v2/userProfileSubcomponents/userEdits/PersonalInfoEdit";

export default function ManualOrderPlace() {
  const [orderStates, setOrderStates] = useState({
    user: null,
    bookingAddress: null,
    readyTime: "13",
    arrivingTime: "",
    celebrationDate: "",
    workHour: 2,
    hubId: "",
    decorImages: [],
    addOns: [],
    coupan: null,
    prepaidDiscount: 0,
    convenienceFee: 0,
    prepaidDiscountAvailable: false,
    freebie: "",
  });

  const [userInfo, setUserInfo] = useState({
    userPersonalInfoEdit: false,
    userInfo: [],
  });

  useEffect(() => {
    setUserInfo((p) => ({ ...p, userInfo: orderStates.user }));
  }, [orderStates.user]);

  const [states, setStates] = useState({
    currentSection: 0,
    maxSections: 4,
  });

  const [orderTotal, setOrderTotal] = useState({
    decor: 0,
    addonsTotal: 0,
    total: 0,
    coupanDiscount: 0,
  });

  const resetOrderStates = () => {
    setOrderStates({
      user: null,
      bookingAddress: null,
      readyTime: "13",
      arrivingTime: "",
      celebrationDate: "",
      workHour: 2,
      hubId: "",
      decorImages: [],
      addOns: [],
      coupan: null,
      prepaidDiscount: 0,
      convenienceFee: 0,
      prepaidDiscountAvailable: false,
      freebie: "",
    });
  };

  const sectionInformation = [
    {
      section: 0,
      sectionName: "User Information",
      component: (
        <UserSelectionContainer
          {...{ states, setStates, orderStates, setOrderStates }}
        />
      ),
    },
    {
      section: 1,
      sectionName: "Address Selection",
      component: (
        <AddressSelectorSection
          {...{ setStates, orderStates, setOrderStates }}
        />
      ),
    },
    {
      section: 2,
      sectionName: "Time slot selection",
      component: (
        <TimeSlotSection
          {...{ states, setStates, orderStates, setOrderStates }}
        />
      ),
    },
    {
      section: 3,
      sectionName: "Product selection",
      component: (
        <ProductSelection
          {...{ states, setStates, orderStates, setOrderStates }}
        />
      ),
    },
    {
      section: 4,
      sectionName: "Bill section & Discount section",
      component: (
        <BillSection
          {...{ states, setStates, orderStates, setOrderStates, orderTotal }}
        />
      ),
    },
  ];

  useEffect(() => {
    const savedOrderData = sessionStorage.getItem("MOP_orderData");
    if (savedOrderData) {
      setOrderStates((p) => ({ ...p, ...JSON.parse(savedOrderData) }));
    }
  }, []);

  useEffect(() => {
    let addonsTotal = calculate_addons_price(orderStates.addOns);
    const coupanDiscount = calculate_offer_discount(
      orderStates.coupan,
      orderStates.product?.sellingPrice || 0
    );

    setOrderTotal({
      decor: orderStates.product?.sellingPrice || 0,
      addonsTotal: addonsTotal,
      coupanDiscount: coupanDiscount,
      total: addonsTotal + (orderStates.product?.sellingPrice || 0),
    });
  }, [
    orderStates.product,
    orderStates.addOns,
    orderStates.coupan,
    orderStates.hubId,
  ]);

  useEffect(() => {
    sessionStorage.setItem("MOP_orderData", JSON.stringify(orderStates));
  }, [orderStates]);

  return (
    <div className="fix-screen bg-blue-100 flex items-center justify-center">
      <PersonalInfoEdit
        parentsStates={userInfo}
        setParentsStates={setUserInfo}
        callback={(user) => {
          setOrderStates((p) => ({
            ...p,
            user: {
              ...p.user,
              name: user.name,
              email: user.email,
              phoneNumber: user.phoneNumber,
              userType: user.userType,
              gender: user.gender,
              dateOfBirth: user.dateOfBirth,
              defaultAddress: user.defaultAddress,
            },
          }));
        }}
      />
      {/* Container */}
      <div className="relative bg-white w-full lg:w-[60rem]  overflow-hidden h-[100dvh] lg:h-[90dvh] shadow-lg rounded-lg flex-col border border-gray-200">
        {/* Steps Bar */}
        <Header
          {...{
            states,
            setStates,
            orderStates,
            setOrderStates,
            sectionInformation,
            setUserInfo,
          }}
        />

        <div
          style={{
            height: "calc(100% - 7rem)",
            paddingTop: !orderStates.bookingAddress ? "1rem" : "4rem",
          }}
          className="w-full p-4 overflow-scroll flex-shrink-0  "
        >
          {sectionInformation[states.currentSection]?.component}
        </div>
        <BottomButtons
          {...{
            states,
            setStates,
            orderStates,
            setOrderStates,
            resetOrderStates,
            orderTotal,
          }}
        />
      </div>
    </div>
  );
}
