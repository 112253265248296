import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaCheck, FaTimes } from "react-icons/fa";

export default function Dropdown({
  options,
  selectedValue,
  onSelect,
  placeholder = "Select an option",
  searchPlaceholder = "Search...",
  size = "default",
  isMultipleSelect = false,
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const wrapperRef = useRef(null);
  const searchInputRef = useRef(null);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const listContainerRef = useRef(null);

  const sizeClasses = {
    default: {
      button: "px-3 py-2 text-sm",
      input: "px-3 py-1.5 text-sm",
      option: "px-4 py-2 text-sm",
    },
    sm: {
      button: "px-2 py-1.5 text-xs",
      input: "px-2 py-1 text-xs",
      option: "px-3 py-1 text-xs",
    },
    xs: {
      button: "px-1 py-0.5 text-xs",
      input: "px-1 py-0.5 text-xs",
      option: "px-2 py-0.5 text-xs",
    },
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (showDropdown) {
      searchInputRef.current?.focus();
    }
  }, [showDropdown]);

  // Filter options based on search term
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleInputKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      if (filteredOptions.length > 0) {
        setHighlightedIndex(0);
        // Move focus from the input to the options container
        searchInputRef.current.blur();
        listContainerRef.current.focus();
      }
      e.preventDefault();
    } else if (e.key === "Enter") {
      let optionToSelect = null;
      if (highlightedIndex === -1 && filteredOptions.length > 0) {
        optionToSelect = filteredOptions[0];
      } else if (
        highlightedIndex >= 0 &&
        highlightedIndex < filteredOptions.length
      ) {
        optionToSelect = filteredOptions[highlightedIndex];
      }
      if (optionToSelect) {
        handleOptionClick(optionToSelect);
        if (!isMultipleSelect) {
          setShowDropdown(false);
        }
      }
      e.preventDefault();
    }
  };

  const handleListKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      if (highlightedIndex < filteredOptions.length - 1) {
        setHighlightedIndex(highlightedIndex + 1);
      }
      e.preventDefault();
    } else if (e.key === "ArrowUp") {
      if (highlightedIndex > 0) {
        setHighlightedIndex(highlightedIndex - 1);
      } else {
        setHighlightedIndex(-1);
        // Return focus to the search input when moving above the first option
        searchInputRef.current.focus();
      }
      e.preventDefault();
    } else if (e.key === "Enter") {
      let optionToSelect = null;
      if (highlightedIndex === -1 && filteredOptions.length > 0) {
        optionToSelect = filteredOptions[0];
      } else if (
        highlightedIndex >= 0 &&
        highlightedIndex < filteredOptions.length
      ) {
        optionToSelect = filteredOptions[highlightedIndex];
      }
      if (optionToSelect) {
        handleOptionClick(optionToSelect);
        if (!isMultipleSelect) {
          setShowDropdown(false);
        }
      }
      e.preventDefault();
    }
  };

  const handleOptionClick = (option) => {
    if (isMultipleSelect) {
      const alreadySelected =
        Array.isArray(selectedValue) &&
        selectedValue.some((item) => item.value === option.value);
      if (!alreadySelected) {
        const newSelection = Array.isArray(selectedValue)
          ? [...selectedValue, option]
          : [option];
        onSelect(newSelection);
      }
      setSearchTerm("");
    } else {
      onSelect(option);
      setShowDropdown(false);
      setSearchTerm("");
    }
  };

  const handleChipRemove = (optionToRemove) => {
    if (isMultipleSelect && Array.isArray(selectedValue)) {
      const newSelection = selectedValue.filter(
        (opt) => opt.value !== optionToRemove.value
      );
      onSelect(newSelection);
    }
  };

  useEffect(() => {
    if (highlightedIndex >= 0) {
      const optionElement = document.getElementById(
        `option-${highlightedIndex}`
      );
      if (optionElement) {
        optionElement.scrollIntoView({ block: "nearest", behavior: "smooth" });
      }
    }
  }, [highlightedIndex]);

  console.log(selectedValue);

  return (
    <div className="relative whitespace-nowrap" ref={wrapperRef}>
      <button
        type="button"
        onClick={() => setShowDropdown(!showDropdown)}
        className={`w-full flex justify-between gap-2 items-center border border-gray-300 bg-white ${
          sizeClasses[size]
            ? sizeClasses[size].button
            : sizeClasses.default.button
        } rounded-md shadow-sm hover:bg-gray-50 focus:ring-1 focus:ring-[#2136d4] transition`}
      >
        <span>
          {isMultipleSelect
            ? Array.isArray(selectedValue) && selectedValue.length > 0
              ? `${selectedValue.length} selected`
              : placeholder
            : selectedValue
            ? options.find((opt) => opt.value === selectedValue)?.label
            : placeholder}
        </span>
        <FaChevronDown className="text-gray-500 text-xs" />
      </button>

      {showDropdown && (
        <div className="absolute left-0 mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-20">
          <div className="p-2">
            <input
              type="text"
              placeholder={searchPlaceholder}
              ref={searchInputRef}
              onKeyDown={handleInputKeyDown}
              className={`w-full ${
                sizeClasses[size]
                  ? sizeClasses[size].input
                  : sizeClasses.default.input
              } border border-gray-300 rounded-md focus:ring-1 focus:ring-[#2136d4]`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {isMultipleSelect &&
            Array.isArray(selectedValue) &&
            selectedValue.length > 0 && (
              <div className="flex flex-wrap gap-2 px-2 py-1">
                {selectedValue.map((option) => (
                  <div
                    key={option.value}
                    className="flex items-center bg-blue-100 text-blue-700 px-2 py-1 rounded-full text-xs"
                  >
                    <span>{option.label}</span>
                    <FaTimes
                      className="ml-1 cursor-pointer"
                      onClick={() => handleChipRemove(option)}
                    />
                  </div>
                ))}
              </div>
            )}
          <div
            className="max-h-40 overflow-auto focus:outline-none"
            tabIndex="0"
            ref={listContainerRef}
            onKeyDown={handleListKeyDown}
          >
            {filteredOptions.map((option, index) => (
              <div
                id={`option-${index}`}
                key={option.value}
                className={`flex justify-between gap-2 flex-shrink-0 items-center ${
                  sizeClasses[size]
                    ? sizeClasses[size].option
                    : sizeClasses.default.option
                } cursor-pointer transition ${
                  highlightedIndex === index &&
                  (!isMultipleSelect
                    ? selectedValue !== option.value
                    : !(
                        Array.isArray(selectedValue) &&
                        selectedValue.some((opt) => opt.value === option.value)
                      ))
                    ? "bg-gradient-to-r from-blue-100 to-white rounded-lg"
                    : ""
                } ${
                  !isMultipleSelect
                    ? selectedValue === option.value
                      ? "blue-gradient rounded-lg"
                      : " hover:bg-blue-50 rounded-lg "
                    : Array.isArray(selectedValue) &&
                      selectedValue.some((opt) => opt.value === option.value)
                    ? "blue-gradient rounded-lg"
                    : " hover:bg-blue-50 rounded-lg "
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
                {selectedValue === option.value && (
                  <FaCheck className=" text-xs flex-shrink-0" />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
