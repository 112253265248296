import React, { useState } from "react";
import { FaPlusCircle, FaTimes } from "react-icons/fa";

export default function AlternateNumbers({ states, setStates }) {
  const [newAltNumber, setNewAltNumber] = useState("");

  const handleAddAlternateNumber = () => {
    if (!/^\d{10}$/.test(newAltNumber)) return;
    setStates((prev) => ({
      ...prev,
      alternateNumbers: [...prev.alternateNumbers, newAltNumber],
    }));
    setNewAltNumber("");
  };

  const handleRemoveAlt = (index) => {
    setStates((prev) => {
      const updated = [...prev.alternateNumbers];
      updated.splice(index, 1);
      return { ...prev, alternateNumbers: updated };
    });
  };

  return (
    <div className="space-y-2">
      {/* Label */}
      <label className="block text-xs font-semibold text-gray-600">
        Alternate Numbers
      </label>

      {/* Existing Numbers List */}
      {states.alternateNumbers?.length > 0 && (
        <div className="flex flex-col space-y-2">
          {states.alternateNumbers.map((num, index) => (
            <div
              key={index}
              className="flex items-center justify-between bg-gray-100 px-3 py-2 rounded-md border border-gray-200"
            >
              <input
                className="bg-transparent border-none text-sm text-gray-800 flex-1 focus:outline-none"
                type="text"
                readOnly
                value={num}
              />
              <button
                type="button"
                className="text-red-500 hover:text-red-700 transition"
                onClick={() => handleRemoveAlt(index)}
              >
                <FaTimes size={14} />
              </button>
            </div>
          ))}
        </div>
      )}

      {/* New Number Input */}
      <div className="flex items-center gap-2">
        <input
          className="border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md px-3 py-2 text-sm flex-1 focus:outline-none transition"
          type="text"
          placeholder="Add new alternate number"
          value={newAltNumber}
          onChange={(e) => {
            if (/^\d{0,10}$/.test(e.target.value)) {
              setNewAltNumber(e.target.value);
            }
          }}
        />

        {/* Add Button (Only visible when valid 10-digit number is entered) */}
        {/^\d{10}$/.test(newAltNumber) && (
          <button
            type="button"
            onClick={handleAddAlternateNumber}
            className="text-blue-600 hover:text-blue-800 transition"
          >
            <FaPlusCircle size={20} />
          </button>
        )}
      </div>
    </div>
  );
}
