import React, { useState } from "react";
import HorizontalAddonCard from "../c/addon/index";

export default function Addons({ cart }) {
  const addOns = cart.addOns;

  const freebie = cart.freebie;

  const [states, setStates] = useState({
    isViewAllAddons: false,
  });

  const addonCount = (addOns?.length || 0) + (freebie?._id ? 1 : 0);

  if (addonCount === 0) return;

  return (
    <div className=" flex-col w-full ">
      <div className=" w-full flex justify-between text-[.875rem] border-t mt-4 pt-2">
        <div className="font-[500] cursor-pointer flex gap-2 items-center">
          Add-ons
          {addonCount && (
            <span
              className="rounded-full flex items-center justify-center w-4 aspect-square 
          text-xs font-semibold text-[#2136d4] bg-[#f0f5fe]"
            >
              {addonCount}
            </span>
          )}
        </div>
      </div>

      {/* presentation of the first addon as a horizontal view */}
      {addonCount > 0 && (
        <div className="w-full flex-col transition-all space-y-4 mt-4">
          {freebie?._id && (
            <HorizontalAddonCard
              {...{
                hubId: cart.hubId,
                addon: {
                  isFreebie: true,
                  _id: freebie?._id,
                  quantity: 1,
                  text: "",
                  addon: {
                    addonName: freebie?.name,
                    addonImage: freebie?.image,
                    price: freebie?.price,
                    discountedPrice: 0,
                  },
                },
              }}
            />
          )}

          <HorizontalAddonCard
            {...{
              hubId: cart.hubId,
              addon: addOns?.[0],
            }}
          />
          {states.isViewAllAddons && (
            <>
              {addOns?.slice(1)?.map?.((curr, key) => {
                return (
                  <HorizontalAddonCard
                    {...{
                      key,
                      hubId: cart.hubId,
                      addon: curr,
                    }}
                  />
                );
              })}
            </>
          )}

          {addOns?.length - 1 > 0 && (
            <div
              onClick={() => {
                setStates((p) => ({
                  ...p,
                  isViewAllAddons: !p.isViewAllAddons,
                }));
              }}
              className="flex items-center justify-center bg-blue-50 rounded-lg py-1 !mt-2 text-sm gap-2 text-[#2136d4] "
            >
              {!states.isViewAllAddons && (
                <div className=" -space-x-5 flex items-center ">
                  {addOns?.slice(1)?.map((curr, idx) => {
                    return (
                      <img
                        key={idx}
                        className=" rounded-full border border-gray-200 p-[1px] w-[2rem] "
                        src={curr?.addon?.addonImage}
                        alt={curr?.addon?.addonName}
                      />
                    );
                  })}
                </div>
              )}
              {addOns?.length > 1 && (
                <div className="font-medium">
                  {states.isViewAllAddons
                    ? "View less"
                    : `${addOns?.length - 1} more item`}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
