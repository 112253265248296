import { v2Api } from "../../api/v2.api";

const v2Crud = async ({
  onInitiate = () => {},
  onFinally = () => {},
  onError = (msg) => {
    window.toast("Something went wrong", "error");
    // console.log(msg);
  },
  onSuccess = () => {},
  queries, // [{ filter , operation , modelName }, {}, {}]
}) => {
  try {
    onInitiate();

    const res = await v2Api({
      /// will call the api to connect with v2Crud controller
      queries,
      tags: ["v2Crud"],
    });

    if (res.isSuccess) {
      onSuccess(res);
    } else {
      console.error(res);
      onError(res?.message || "Something went wrong");
    }
  } catch (error) {
    console.error(error);
    onError(error?.message);
    // console.error(error);
  } finally {
    onFinally();
  }
};

/**
 * @doc: type object
 * @filter: type object
 * @update: type object
 * @options: type object
 * @operation: type string
 * @modelName: type string
 * @populates: type array
 * @pipeline: type array
 * @selects: type array
 * @sort: type object
 * @skip: type number
 * @limit: type number
 * @param {Object} {docs, filter, update, options, operation, modelName, populates, pipeline, selects, sort, skip, limit}
 */
class V2CrudOptionsModel {
  constructor({
    tags = [],
    docs = {},
    id = "",
    filter = {},
    update = {},
    options = {},
    operation = "read", // "read", "create", "update", "delete", "readAggregate", "getMaterials"
    modelName = "", // Video, Order, Product, Addon, Offer, Freebie, Santa, User, Enquiry, Addresses, Service_Hub, Call_log, santa_wallet, Payment, MasterSheet, Inventory_Items, Admin, Error
    populates = [],
    pipeline = [],
    selects = [],
    sort = { createdAt: -1 },
    skip = 0,
    limit = Math.pow(10, 6),
  }) {
    this.docs = docs; // {} doc will use while create a doc in database
    this.filter = filter; // {} doc will use while filtering a doc in database
    this.update = update; // {} doc will use while updating a doc in database
    this.options = options; // {} doc will use while updating a doc in database in options param
    this.operation = operation; // "read", "create", "update", "delete"
    this.modelName = modelName; // "Order", "User", "Video"
    this.populates = populates; // []
    this.pipeline = pipeline; // []
    this.selects = selects; // []
    this.sort = sort; // {}
    this.skip = skip; // number
    this.limit = limit; // number
    this.tags = tags || []; // number
    this.id = id; // number
  }
}

export { v2Crud, V2CrudOptionsModel };
