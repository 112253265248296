import React from "react";
import AddressSelect from "../../../../maps/addressSelect/AddressSelect";

export default function AddressSelectMap({ onClose, address, callback }) {
  return (
    <div className="fixed inset-0 z-50">
      {/* Overlay */}
      <div
        onClick={onClose}
        className="absolute inset-0 bg-black opacity-40"
      ></div>
      <div className="z-10 bg-white w-[28rem] max-w-full fixed bottom-0 top-0 h-dvh">
        <AddressSelect {...{ callback, onClose, selectedAddress: address }} />
      </div>
    </div>
  );
}
