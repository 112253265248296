import React, { useState, useRef, useEffect } from "react";

function SetDuelModal({ onCallback }) {
  const [selectedType, setSelectedType] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState("");

  const handleSubmit = () => {
    if (selectedType && selectedDate) {
      onCallback({ selectedType, selectedDate });
    } else {
      alert("Please select a type and a date");
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-bold mb-4">Set Duel Options</h2>
      <div className="flex gap-2 mb-4">
        {["Type 1", "Type 2", "Type 3"].map((type) => (
          <div
            key={type}
            className={`cursor-pointer px-3 py-1 rounded-full border ${
              selectedType === type
                ? "bg-blue-500 text-white"
                : "bg-gray-100 text-black"
            }`}
            onClick={() => setSelectedType(type)}
          >
            {type}
          </div>
        ))}
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Select Date</label>
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="border rounded p-1"
        />
      </div>
      <button
        onClick={handleSubmit}
        className="px-4 py-2 bg-green-500 text-white rounded"
      >
        Submit
      </button>
    </div>
  );
}

export default function StatusPicker({ states, setStates }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const STATUS_OPTIONS = [
    { value: "open", label: "Open", color: "bg-green-500", text: "text-white" },
    {
      value: "pending",
      label: "Pending",
      color: "bg-orange-500",
      text: "text-white",
    },
    {
      value: "scheduled",
      label: "Scheduled",
      color: "bg-purple-500",
      text: "text-white",
    },
    {
      value: "converted",
      label: "Converted",
      color: "blue-gradient",
      text: "text-white",
    },
    {
      value: "dropped",
      label: "Dropped",
      color: "bg-red-500",
      text: "text-white",
    },
  ];

  const currentStatus =
    STATUS_OPTIONS.find((opt) => opt.value === states.status) ||
    STATUS_OPTIONS[0];

  return (
    <div className="relative inline-block" ref={wrapperRef}>
      {/* Status Button */}
      <button
        type="button"
        onClick={() => setShowDropdown((prev) => !prev)}
        className={`flex items-center gap-2 pr-3 pl-4 py-1 font-medium rounded-full shadow-sm ${currentStatus.color} ${currentStatus.text} hover:opacity-90 transition`}
      >
        {currentStatus.label}
        <svg
          className="ml-1 w-3.5 h-3.5"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          viewBox="0 0 24 24"
        >
          <path d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {/* Dropdown Menu */}
      {showDropdown && (
        <div className="absolute right-0 mt-2 w-[8rem] bg-white border border-gray-200 rounded-lg shadow-lg z-20">
          {STATUS_OPTIONS.map((opt) => (
            <div
              key={opt.value}
              className={`flex items-center justify-between px-2 py-1.5 cursor-pointer text-xs rounded-md transition ${
                opt.value === states.status
                  ? "bg-gray-100"
                  : "hover:bg-gray-200"
              }`}
              onClick={() => {
                if (opt.value === "scheduled") {
                  window.floatingModal(
                    true,
                    <SetDuelModal
                      onCallback={(data) => {
                        setStates((prev) => ({
                          ...prev,
                          status: opt.value,
                          duelData: data,
                        }));
                      }}
                    />,
                    {
                      position: "l",
                      animation: window.innerWidth <= 768 ? "b-t" : "l-r",
                      animationLength:
                        window.innerWidth > 768 ? "40rem" : "100vh",
                    },
                    {}
                  );
                } else {
                  setStates((prev) => ({ ...prev, status: opt.value }));
                }
                setShowDropdown(false);
              }}
            >
              {/* Status Label with Icon - Improved Layout */}
              <div
                className={`w-full text-center gap-1 px-2 py-1.5 rounded-full ${opt.color} ${opt.text} shadow-sm`}
              >
                <span className="text-[10px] font-medium">{opt.label}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
