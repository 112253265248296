import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import AdminContext from "../context/AdminContext";
import { AiFillCode, AiFillFund, AiFillGift, AiFillHome } from "react-icons/ai";
import { ImLocation, ImStatsDots } from "react-icons/im";
import { GiBackup } from "react-icons/gi";
import { RiRoadMapFill } from "react-icons/ri";
import {
  MdAddToHomeScreen,
  MdAttachMoney,
  MdCelebration,
  MdSecurityUpdateWarning,
  MdSwitchAccessShortcutAdd,
} from "react-icons/md";
import {
  BsFillCalendarCheckFill,
  BsFillCartFill,
  BsFillCartPlusFill,
  BsFillPostageHeartFill,
  BsFillTagFill,
  BsPencilSquare,
  BsTools,
} from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import { RiTeamFill, RiUser2Fill } from "react-icons/ri";
import { TbFileInvoice } from "react-icons/tb";
import { motion } from "framer-motion";

// ----------------- ** SIDEBAR NAVIGATION MAIN COMPONENT ** --------------
export default function Sidebar({ placement }) {
  // ------------ ** CONTEXT ** ---------------------

  //--------------- ** STATES ** ----------------

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  // --------------- ** REACT ROUTER DOM HOOKS ** ----------------

  // -------------- ** FUNCTIONS ** -------------------

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      <>
        {placement === "isTopNavbar" ? (
          <button
            onClick={toggleDrawer("right", true)}
            className="flex border shadow-md w-full border-gray-200 text-[1.25rem] px-4 hover:blue-gradient items-center gap-2 rounded-md"
          >
            <i className="uil uil-bars text-xl " />
            <span className="font-[400] text-[10px] ">More options</span>
          </button>
        ) : (
          <button
            onClick={toggleDrawer("right", true)}
            className="flex border shadow-md bg-white  w-full border-gray-200 text-[1.25rem] py-2  px-4 hover:blue-gradient items-center gap-2 rounded-md "
          >
            <i className="uil uil-bars text-2xl " />
            <span className="font-[400] text-[10px] ">More options</span>
          </button>
        )}

        <SwipeableDrawer
          anchor="right"
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {/* {List("left")} */}
          <List anchor={"right"} toggleDrawer={toggleDrawer} />
        </SwipeableDrawer>
      </>
    </div>
  );
}

const List = ({ anchor, toggleDrawer }) => {
  const { logout } = React.useContext(AdminContext);
  const navigate = useNavigate();

  return (
    <Box sx={{ width: 300, padding: "10px 10px" }} role="presentation">
      <div className="p-5">
        <div
          className=" xl:text-[2.6rem] relative lg:text-[2.3rem] items-center  flex justify-center gap-2 text-[#2136d4] font-[800]  "
          style={{ fontFamily: "Josefin Sans, sans-serif" }}
        >
          <h1>ebo</h1>
        </div>
      </div>
      <Divider />
      <div className="flex flex-col gap-2 m-4">
        {DashNav.map((currLink, index) => {
          if (currLink.isDropDown) {
            return (
              <motion.div
                className="w-full  min-h-[2.7rem] h-[2.7rem] hover:h-[auto] overflow-hidden hover:bg-[#a7e1f052] flex flex-col"
                key={index}
              >
                <div className=" flex w-full py-1 items-center">
                  <span className="text-[1.25rem] mx-2">{currLink.icon}</span>
                  <span>{currLink.name}</span>
                </div>
                <div className=" pl-4 py-2 mt-1 flex flex-col   ">
                  {currLink.subList.map((currSubLink, idx) => {
                    return (
                      <motion.div
                        onClick={() => {
                          console.log("s", currSubLink.location);
                          navigate(currSubLink.location);
                          toggleDrawer(anchor, false);
                        }}
                        whileHover={{
                          backgroundColor: "#abbbbe52",
                        }}
                        className=" flex w-full py-1 items-center"
                        key={idx}
                      >
                        <span className="text-[1.25rem] mx-2">
                          {currSubLink.icon}
                        </span>
                        <span>{currSubLink.name}</span>
                      </motion.div>
                    );
                  })}
                </div>
              </motion.div>
            );
          } else {
            return (
              <motion.div
                className="w-full py-2  flex flex-col"
                key={`${index}_link`}
                onClick={() => {
                  navigate(currLink.location);
                  toggleDrawer(anchor, false);
                }}
                whileHover={{
                  backgroundColor: "#a7e1f052",
                }}
              >
                <div className=" flex  items-center">
                  <span className="text-[1.25rem] mx-2">{currLink.icon}</span>
                  <span>{currLink.name}</span>
                </div>
              </motion.div>
            );
          }
        })}
        <div
          className="w-full  flex flex-col"
          onClick={() => {
            logout();
            navigate("/");
          }}
        >
          Logout
        </div>
      </div>
    </Box>
  );
};

const DashNav = [
  {
    location: "/",
    name: "Home",
    icon: <AiFillHome />,
  },
  {
    isDropDown: true,
    subList: [
      {
        location: "/order",
        name: "Orders",
        icon: <BsFillCartFill />,
      },
      {
        location: "/sales/transactions",
        name: "Transactions",
        icon: <MdAttachMoney />,
      },
    ],
    name: "Sales",
    icon: <AiFillFund />,
  },
  {
    isDropDown: true,
    subList: [
      {
        location: "/team/santa",
        name: "Santa",
        icon: <RiUser2Fill />,
      },
      {
        location: "/team/affiliators",
        name: "Affiliators",
        icon: <GiBackup />,
      },
    ],
    name: "Team",
    icon: <RiTeamFill />,
  },
  {
    isDropDown: true,
    subList: [
      {
        location: "/Admin/product",
        name: "Decors",
        icon: <BsFillPostageHeartFill />,
      },
      {
        location: "/Admin/AddOn",
        name: "AddOns",
        icon: <MdSwitchAccessShortcutAdd />,
      },
      {
        location: "/Admin/FreeBie",
        name: "FreeBies",
        icon: <AiFillGift />,
      },
    ],
    name: "Product",
    icon: <BsFillPostageHeartFill />,
  },
  {
    isDropDown: true,
    subList: [
      {
        location: "/location",
        name: "Service Locations",
        icon: <RiRoadMapFill />,
      },
    ],
    name: "Locaton",
    icon: <ImLocation />,
  },
  {
    location: "/Admin/stats",
    name: "Statistics",
    icon: <ImStatsDots />,
  },

  {
    location: "/Admin/Celebration",
    name: "Upcoming Celebrations",
    icon: <MdCelebration />,
  },
  {
    location: "/timeslot-detail",
    name: "Time Slot",
    icon: <BsFillCalendarCheckFill />,
  },

  {
    location: "/homepage",
    name: "Home page",
    icon: <MdAddToHomeScreen />,
  },

  {
    location: "/Admin/Coupans",
    name: "Coupans",
    icon: <BsFillTagFill />,
  },
  {
    location: "/blog",
    name: "Blogs",
    icon: <BsPencilSquare />,
  },
  {
    location: "/Admin/user",
    name: "User",
    icon: <FaUserAlt />,
  },
  {
    location: "/Admin/Checkout",
    name: "Checkout",
    icon: <BsFillCartFill />,
  },
  {
    location: "/loggers",
    name: "Logger Tracking",
    icon: <MdSecurityUpdateWarning />,
  },
  {
    location: "/product/feedback",
    name: "Product Feedback",
    icon: <MdSecurityUpdateWarning />,
  },
  {
    location: "/admin/traffic",
    name: "Traffic",
    icon: <MdAttachMoney />,
  },
  {
    location: "/inventory",
    name: "Inventory",
  },
  {
    isDropDown: true,
    subList: [
      {
        location: "/order/manual-order-place",
        name: "Manual order place",
        icon: <BsFillCartPlusFill />,
      },
      {
        location: "/Admin/SitemapGenerator",
        name: "Sitemap Generator",
        icon: <AiFillCode />,
      },
      {
        location: "/invoice-generator",
        name: "Custom Invoice Generator",
        icon: <TbFileInvoice />,
      },
    ],
    name: "Tools",
    icon: <BsTools />,
  },
];
