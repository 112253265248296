import React, { useState, useRef } from "react";
import { RxCross2 } from "react-icons/rx";

/**
 * Renders a tag strip + an "Add Tag" input with an autocomplete dropdown.
 *
 * @param {Array<string>} selectedTags - e.g. ["hot", "followup", ...]
 * @param {Function} setSelectedTags - function to update parent's tag array
 * @param {Array<{ label, value, theme }>} predefinedTags
 */
export default function TagsManager({
  selectedTags = [],
  setSelectedTags,
  predefinedTags = [],
}) {
  const [isAdding, setIsAdding] = useState(false); // controlling input vs. "+ Add tag"
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  // Filter out predefined tags that are *not* already selected
  const availableTags = predefinedTags.filter(
    (tag) => !selectedTags.includes(tag.value)
  );

  // Filter them based on user input
  const filteredTags = availableTags.filter((tag) =>
    tag.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  // Add a tag by 'value'
  const handleAddTag = (tagValue) => {
    if (!selectedTags.includes(tagValue)) {
      setSelectedTags([...selectedTags, tagValue]);
    }
    // Clear input & close
    setInputValue("");
    setIsAdding(false);
  };

  // Remove a tag
  const handleRemoveTag = (tagValue) => {
    const updated = selectedTags.filter((v) => v !== tagValue);
    setSelectedTags(updated);
  };

  // If user presses Enter while typing
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // If the typed text matches exactly one of the suggestions, use that
      const matched = filteredTags.find(
        (t) => t.label.toLowerCase() === inputValue.toLowerCase()
      );
      if (matched) {
        handleAddTag(matched.value);
      } else {
        // For this example, we only allow from predefined
        // If you wanted to allow custom tags, do that here.
      }
    } else if (e.key === "Escape") {
      // Cancel adding
      setInputValue("");
      setIsAdding(false);
    }
  };

  return (
    <div className="flex flex-wrap text-xs items-center gap-2 flex-shrink-0 w-full">
      {/* Render each selected tag as a "pill" */}
      {selectedTags.map((tagValue) => {
        const found = predefinedTags.find((pt) => pt.value === tagValue);
        // fallback label, theme if not found
        const label = found?.label || tagValue;
        const theme = found?.theme || "bg-gray-100 text-gray-800";

        return (
          <div
            key={tagValue}
            className={`flex items-center px-2 py-1 rounded-md text-xs ${theme}`}
          >
            <span>{label}</span>
            <button
              type="button"
              onClick={() => handleRemoveTag(tagValue)}
              className="ml-1"
            >
              <RxCross2 className="" />
            </button>
          </div>
        );
      })}

      {/* "Add Tag" area */}
      {isAdding ? (
        <div className="relative">
          <input
            ref={inputRef}
            type="text"
            autoFocus
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            className="border-b border-gray-300 focus:border-[#2136d4] focus:outline-none text-sm px-1"
            placeholder="Type to search..."
            style={{ minWidth: "100px" }}
          />
          {/* Suggestions dropdown */}
          {filteredTags.length > 0 && (
            <div className="absolute top-full left-0 w-full bg-white border border-gray-200 rounded shadow-md mt-1 z-10">
              {filteredTags.map((tag) => (
                <div
                  key={tag.value}
                  onClick={() => handleAddTag(tag.value)}
                  className="px-2 py-1 text-xs cursor-pointer hover:bg-gray-100"
                >
                  {tag.label}
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <button
          type="button"
          onClick={() => setIsAdding(true)}
          className="text-xs text-gray-500 flex items-center"
        >
          <span className="mr-1 font-semibold ">+</span> Add tag
        </button>
      )}
    </div>
  );
}
