import React from "react";
import Addons from "./c/Addons";
import useCartManager from "../../../../../hooks/cart/useCartManager";

export default function ProductContainer({ cart }) {
  const product = cart.product;

  const { calculateAdvancedCartPricing } = useCartManager();

  const pricing = calculateAdvancedCartPricing(cart);

  return (
    <div className="rounded-lg border border-gray-200 bg-white p-4 relative">
      {/* product info section  */}
      <div className="flex gap-2">
        <img
          src={product?.images[0]}
          alt="product"
          className=" w-[4rem] border rounded-md"
        />
        <div className=" flex flex-col justify-between w-full">
          <div className="flex flex-col ">
            <h2 className="font-medium text-sm pr-6 leading-[18px] pb-1 ">
              {product?.name}
            </h2>

            <div className="flex-col items-start ">
              <div className="flex items-center gap-2">
                <span className="text-[1rem]  font-[600]">
                  ₹{pricing?.productSellingPrice}
                </span>
                <span className="line-through text-[grey] text-[.825rem]">
                  ₹{pricing?.productCostPrice}
                </span>
                <span className=" text-[#00A807]   text-[.825rem] font-[500] ">
                  {pricing?.productWebDiscount}% off
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Addons cart={cart} />
      {cart?.instructions && (
        <>
          <div className=" w-full cursor-pointer flex justify-between items-center  border-t mt-2 pt-2">
            <div className="font-[500] text-[.875rem]  flex gap-2 items-center">
              Decor service instruction
            </div>
          </div>

          <div className=" text-[.825rem] w-full text-[grey] ">
            {cart?.instructions}
          </div>
        </>
      )}
    </div>
  );
}
