import React from "react";
import { FaRupeeSign } from "react-icons/fa";

export default function BudgetField({ states, setStates }) {
  const handleBudgetChange = (index, value) => {
    // Ensure only numeric input
    if (!/^\d*$/.test(value)) return;

    const updatedBudget = [...states.budget];
    updatedBudget[index] = value ? parseInt(value, 10) : 0;
    setStates((prev) => ({ ...prev, budget: updatedBudget }));
  };

  return (
    <div className="space-y-2">
      {/* Label */}
      <label className="block text-xs font-semibold text-gray-600">
        Budget Range (₹)
      </label>

      {/* Budget Input Fields */}
      <div className="flex items-center gap-3">
        {/* Min Budget */}
        <div className="relative flex-1">
          <FaRupeeSign className="absolute left-3 top-3 text-gray-500 text-xs" />
          <input
            type="text"
            className="border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md px-8 py-2 text-sm w-full shadow-sm transition"
            placeholder="Min"
            value={states.budget[0] || ""}
            onChange={(e) => handleBudgetChange(0, e.target.value)}
          />
        </div>

        {/* Separator */}
        <span className="text-gray-500 text-sm">to</span>

        {/* Max Budget */}
        <div className="relative flex-1">
          <FaRupeeSign className="absolute left-3 top-3 text-gray-500 text-xs" />
          <input
            type="text"
            className="border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md px-8 py-2 text-sm w-full shadow-sm transition"
            placeholder="Max"
            value={states.budget[1] || ""}
            onChange={(e) => handleBudgetChange(1, e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
