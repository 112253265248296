import React, { useState, useEffect } from "react";
import moment from "moment";
import { FaClock, FaTimes } from "react-icons/fa";

// Time Slots
const HOURS = [
  "12:00 AM",
  "1:00 AM",
  "2:00 AM",
  "3:00 AM",
  "4:00 AM",
  "5:00 AM",
  "6:00 AM",
  "7:00 AM",
  "8:00 AM",
  "9:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "1:00 PM",
  "2:00 PM",
  "3:00 PM",
  "4:00 PM",
  "5:00 PM",
  "6:00 PM",
  "7:00 PM",
  "8:00 PM",
  "9:00 PM",
  "10:00 PM",
  "11:00 PM",
];

// Function to determine time period
function getTimePeriod(timeString) {
  const hour = moment(timeString, "h:mm A").hour();
  if (hour >= 0 && hour <= 6) return "midnight";
  if (hour > 6 && hour <= 12) return "morning";
  if (hour > 12 && hour <= 16) return "afternoon";
  if (hour > 16 && hour <= 19) return "evening";
  return "night";
}

// Time period categories
const TIME_CHIPS = [
  { label: "Morning", value: "morning" },
  { label: "Afternoon", value: "afternoon" },
  { label: "Evening", value: "evening" },
  { label: "Night", value: "night" },
  { label: "Mid Night", value: "midnight" },
];

export default function TimePickerModal({
  onClose,
  onSelectTime,
  selectedReadyTime,
}) {
  const [activeChip, setActiveChip] = useState("evening");

  useEffect(() => {
    if (selectedReadyTime) {
      const period = getTimePeriod(selectedReadyTime);
      setActiveChip(period);
    }
  }, [selectedReadyTime]);

  const filteredHours = HOURS.filter(
    (timeStr) => getTimePeriod(timeStr) === activeChip
  );

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40 backdrop-blur-md">
      {/* Modal Container */}
      <div className="relative z-10 bg-white p-5 rounded-lg shadow-lg w-[90%] max-w-md">
        {/* Header */}
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold text-gray-800 flex items-center gap-2">
            <FaClock className="text-blue-600" /> Select Ready Time
          </h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-red-600 transition"
            title="Close"
          >
            <FaTimes size={16} />
          </button>
        </div>

        {/* Time Period Chips */}
        <div className="flex gap-2 overflow-x-auto pb-2">
          {TIME_CHIPS.map((chip) => (
            <button
              key={chip.value}
              onClick={() => setActiveChip(chip.value)}
              className={`px-3 py-1.5 text-xs rounded-full font-medium border transition ${
                activeChip === chip.value
                  ? "bg-blue-600 text-white border-blue-600"
                  : "bg-white text-gray-700 border-gray-300 hover:bg-gray-100"
              }`}
            >
              {chip.label}
            </button>
          ))}
        </div>

        {/* Time Slots */}
        <div className="grid grid-cols-3 gap-2 mt-4">
          {filteredHours.map((hour) => (
            <button
              key={hour}
              onClick={() => onSelectTime(hour)}
              className={`py-2 text-xs rounded-md border text-center font-medium transition ${
                hour === selectedReadyTime
                  ? "bg-blue-600 text-white border-blue-600 shadow-md"
                  : "bg-gray-100 text-gray-700 border-gray-300 hover:bg-gray-200"
              }`}
            >
              {hour}
            </button>
          ))}
        </div>

        {/* Cancel Button */}
        <button
          onClick={onClose}
          className="mt-4 w-full bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium py-2 rounded-md transition"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
