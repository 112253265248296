import React, { useCallback } from "react";
import createNewEnquiryHelper from "../f/createNewEnquiryHelper";
import { useAdminContext } from "../../../../context/AdminContext";
import { useStoreContext } from "../../../../context/StoreContext";

export default function Bottom({
  states,
  height,
  setStates,
  onCreateCallback = ({ isSuccess, enquiry }) => {},
}) {
  const {
    adminState: { adminInfo },
  } = useAdminContext();

  const {
    storeStates: { admins },
  } = useStoreContext();

  // Encapsulate the click handler in a useCallback (optional optimization)
  const handleClick = useCallback(
    (expert) => {
      const isAssigningToMe = states.assignTo === adminInfo?._id;

      // Call your async logic (handleAssignToMe)
      createNewEnquiryHelper().handleAssignToMe({
        states,
        setStates,
        expert,
        isAssigningToMe,
        allAdmins: admins,
        callback: onCreateCallback,
      });
    },
    [states, setStates, adminInfo, admins, onCreateCallback]
  );

  const isAssigningToMe = states.assignTo === adminInfo?._id;

  const expertDetailsWhomToAssign = isAssigningToMe
    ? adminInfo
    : admins?.find((curr) => curr._id === states.assignTo);

  return (
    <div
      className="absolute bottom-0 left-0 px-2 pb-2 right-0 flex-center"
      style={{ height }}
    >
      <button
        className="blue-gradient text-sm font-semibold p-2 w-full flex-center h-full rounded-lg"
        onClick={() => {
          handleClick(expertDetailsWhomToAssign);
          // Call the function to create a new enquiry
        }}
        // Optionally, disable the button while isCreating is true
        disabled={states.isCreating}
      >
        {states.isCreating ? (
          <div
            className="loader w-[1rem]"
            style={{
              borderTopColor: "white",
            }}
          ></div>
        ) : (
          `Assign to ${
            isAssigningToMe
              ? "me"
              : expertDetailsWhomToAssign?.userName || "Expert"
          }`
        )}
      </button>
    </div>
  );
}
