import moment from "moment";
import { BiCalendar } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import { RiUser2Line } from "react-icons/ri";
import { RxTimer } from "react-icons/rx";
import { MdOutlineWorkOutline } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { GoHome } from "react-icons/go";
import { FaDirections, FaMobileAlt } from "react-icons/fa";
import useCartManager from "../../../../hooks/cart/useCartManager";

export default function DateAddress({ cart }) {
  const celebrationDate = cart?.celebrationDate;
  const readyTime = cart?.readyTime;
  const arrivingTime = cart?.arrivingTime;

  return (
    <div className="flex flex-col relative w-full border border-gray-300 rounded-lg p-4">
      {/* Address Section */}
      {cart.bookingAddress && (
        <div
          onClick={() => {
            window.openUrl(
              `https://www.google.com/maps?q=${cart.bookingAddress?.locationv2.lat},${cart.bookingAddress?.locationv2.lng}`
            );
          }}
          className="absolute right-3 top-3 z-[2] cursor-pointer blue-gradient rounded-full size-[2rem]  flex-center text-sm"
        >
          <FaDirections />
        </div>
      )}

      <div className="w-full pb-3 border-b border-gray-200">
        <div className="text-lg font-semibold flex items-center gap-2">
          <FaLocationDot className="text-red-500 text-lg" />
          Address
        </div>
        {cart.bookingAddress ? (
          <AddressPreview cart={cart} />
        ) : (
          <div className=" text-xs font-medium text-red-600 ">
            Address not selected
          </div>
        )}
      </div>

      {/* Celebration Date */}
      <InfoRow
        icon={<BiCalendar className="text-blue-500 text-lg" />}
        label="Celebrating on"
        value={moment(celebrationDate).format("ddd, Do MMM")}
      />

      {/* Ready Time */}
      <InfoRow
        icon={<RxTimer className="text-green-500 text-lg" />}
        label="Ready by"
        value={moment(readyTime, "HH").format("hh:00 A")}
      />

      {/* Arrival Time */}
      <InfoRow
        icon={<RiUser2Line className="text-purple-500 text-lg" />}
        label="Santa will arrive between"
        value={`${moment(arrivingTime, "HH").format("hh:00 A")} - ${moment(
          arrivingTime,
          "HH"
        )
          .add(1, "hours")
          .format("hh:00 A")}`}
      />
    </div>
  );
}

const AddressPreview = ({ cart }) => {
  const { getFormattedAddress } = useCartManager();
  const address = getFormattedAddress(cart);

  return (
    <div className="w-full flex gap-2 pl-6 text-sm font-medium text-gray-700">
      <div className="flex flex-col w-full">
        {/* Name & Label */}
        <div className="flex items-center gap-2 text-gray-900 font-semibold">
          <span className="text-base capitalize">{address?.name}</span>
          <div className="flex items-center text-xs rounded-full px-2 py-1 capitalize font-medium gap-1 bg-blue-100 text-blue-600">
            {address.addressLabel === "home" ? (
              <GoHome className="text-base" />
            ) : address.addressLabel === "work" ? (
              <MdOutlineWorkOutline className="text-lg" />
            ) : (
              <IoLocationOutline className="text-lg" />
            )}
            <span>{address.addressLabel}</span>
          </div>
        </div>

        {/* Address Line */}
        <div className="text-gray-500 text-sm mt-1 tracking-wide">
          {address.line2}
        </div>

        {/* Mobile Number */}
        <div className="flex items-center gap-1 mt-2 text-gray-600">
          <FaMobileAlt className="text-xs" />
          {address?.mobileNumber}
        </div>
      </div>
    </div>
  );
};

// Reusable Row Component
const InfoRow = ({ icon, label, value }) => (
  <div className="flex flex-col w-full mt-3">
    <div className="text-sm text-gray-500 font-medium flex items-center gap-2">
      {icon}
      {label}
    </div>
    <div className="text-base font-semibold pl-7 text-gray-900">{value}</div>
  </div>
);
