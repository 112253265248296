import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaCheck } from "react-icons/fa";

export default function OccasionField({
  states,
  setStates,
  predefinedOccasions,
  isOccasionOther,
  setIsOccasionOther,
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const wrapperRef = useRef(null);

  // Close dropdown if clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleOccasionChange = (value) => {
    if (value === "other") {
      setIsOccasionOther(true);
      setStates((prev) => ({ ...prev, occasionName: "" }));
    } else {
      setIsOccasionOther(false);
      setStates((prev) => ({ ...prev, occasionName: value }));
    }
    setShowDropdown(false);
  };

  return (
    <div className="space-y-2 relative" ref={wrapperRef}>
      {/* Label */}
      <label className="block text-xs font-semibold text-gray-600">
        Occasion
      </label>

      {/* Dropdown Select */}
      {!isOccasionOther ? (
        <div className="relative">
          <button
            type="button"
            onClick={() => setShowDropdown(!showDropdown)}
            className="w-full flex justify-between items-center border border-gray-300 bg-white px-3 py-2 rounded-md text-sm shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-blue-500 transition"
          >
            <span>
              {predefinedOccasions.find((o) => o.value === states.occasionName)
                ?.label || "Select Occasion"}
            </span>
            <FaChevronDown className="text-gray-500 text-xs" />
          </button>

          {showDropdown && (
            <div className="absolute left-0 mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-20">
              {predefinedOccasions.map((oc) => (
                <div
                  key={oc.value}
                  className={`flex justify-between items-center px-4 py-2 cursor-pointer text-sm hover:bg-gray-100 transition ${
                    states.occasionName === oc.value ? "bg-gray-200" : ""
                  }`}
                  onClick={() => handleOccasionChange(oc.value)}
                >
                  {oc.label}
                  {states.occasionName === oc.value && (
                    <FaCheck className="text-blue-600 text-xs" />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        // Custom Occasion Input
        <div className="flex items-center gap-2">
          <input
            type="text"
            className="border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md px-3 py-2 text-sm flex-1 focus:outline-none transition"
            placeholder="Enter custom occasion"
            value={states.occasionName}
            onChange={(e) =>
              setStates((prev) => ({ ...prev, occasionName: e.target.value }))
            }
          />
          {/* Reset Button (Close "Other" Mode) */}
          <button
            type="button"
            className="text-gray-500 hover:text-red-500 transition p-2 rounded-md"
            onClick={() => {
              setIsOccasionOther(false);
              setStates((prev) => ({ ...prev, occasionName: "" }));
            }}
          >
            ✕
          </button>
        </div>
      )}
    </div>
  );
}
