import React, { useEffect, useMemo, useState } from "react";
import Header from "./c/Header";
import Bottom from "./c/Bottom";
import { useStoreContext } from "../../../context/StoreContext";
import editEnquiryHelper from "./f/editEnquiryHelper";

// Smaller components
import UserHeader from "./c/UserHeader";
import AlternateNumbers from "./c/AlternateNumbers";
import CityField from "./c/CityField";
import OccasionField from "./c/OccasionField";
import BudgetField from "./c/BudgetField";
import CelebrationDateField from "./c/CelebrationDateField";
import ReadyTimeField from "./c/ReadyTimeField.jsx";
import WhoIsCelebratingField from "./c/WhoIsCelebratingField";
import AreaField from "./c/AreaField";
import ExpertSwitch from "./c/ExpertSwitch.jsx";
import Notes from "../../notes/notesUI1/index.jsx";
import { useAdminContext } from "../../../context/AdminContext.js";
import TagsManager from "../../tagManager/tagManger1/TagsManager.jsx";
import StatusPicker from "./c/StatusPicker.jsx";
import enquiryUtils from "../../../utils/adminUtils/enquiry/enquiryUtils.js";
import moment from "moment";
import AddressSelectMap from "./c/addressSelect/AddressSelectMap.jsx";
import { FaShoppingCart } from "react-icons/fa";
import CartModal from "./c/cart/CartModal.jsx";

export default function EnquiryEditModal({
  compRenderStates,
  setCompRenderStates,
}) {
  const {
    storeStates: { serviceableCities, admins },
  } = useStoreContext();

  const {
    adminState: { adminInfo: currentAdmin },
  } = useAdminContext();

  const [notes, setNotes] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const {
    enquiryEditModal: { enquiryData, onEditCallBack }, // must contain userId and _id.
  } = compRenderStates;

  // enquiryData may contain cart , wishlists ...

  // Convert serviceableCities object into array + add an Other option
  const cityOptions = useMemo(() => {
    const arr = Object.values(serviceableCities);
    return [...arr, { city: "other", label: "Other" }];
  }, [serviceableCities]);

  const [states, setStates] = useState({
    isLoadingEnquiry: false,
    isSavingEnquiry: false,
    isEnquiryNotFound: false,
    isAddressSelectionModalOpen: false,
    isCartModalOpen: false,
    // Enquiry fields
    userId: {}, // from DB
    alternateNumbers: [],
    occasionName: "",
    city: "",
    budget: [0, 0], // [minPrice, maxPrice]
    celebrationDate: "",
    readyTime: "", // e.g. "09:00 AM"
    assignedExperts: [],
    status: "",
    address: {
      address1: "",
      address2: "",
      lat: null,
      lng: null,
      city: "",
    },
    whoIsCelebrating: {
      name: "",
      phoneNumber: "",
      age: "",
      relationShip: "",
      gender: "",
    },
    area: "",
    fullEnquiryObj: {},
  });

  // For controlling whether city is "Other"
  const [isCityOther, setIsCityOther] = useState(false);
  // For controlling whether occasion is "Other"
  const [isOccasionOther, setIsOccasionOther] = useState(false);

  useEffect(() => {
    // If we have an enquiry _id, fetch its data
    onInitialLoad();

    // eslint-disable-next-line
  }, [enquiryData]);

  const onInitialLoad = async () => {
    try {
      if (enquiryData && enquiryData._id) {
        setStates((p) => ({ ...p, isLoadingEnquiry: true }));
        let enquiry = enquiryData;
        if (Object.keys(enquiryData).length >= 1) {
          const res = await editEnquiryHelper().getEnquiryDetails(
            enquiryData._id
          );
          if (res.enquiry) {
            const fetched = res.enquiry;
            enquiry = fetched;
          } else {
            setStates((p) => ({
              ...p,
              isEnquiryNotFound: true,
              isLoadingEnquiry: false,
            }));
          }
        }

        setStates((p) => ({
          ...p,
          isLoadingEnquiry: false,
          isEnquiryNotFound: false,
          // Fill in with existing enquiry data:
          userId: enquiry.userId || {},
          _id: enquiry._id,
          alternateNumbers: enquiry.alternateNumbers || [],
          occasionName: enquiry.occasionName || "",
          city: enquiry.city || "",
          budget: enquiry.budget || [0, 0],
          address: enquiry.address || {
            address1: "",
            address2: "",
            lat: null,
            lng: null,
            city: "",
          },
          celebrationDate: enquiry.celebrationDate
            ? new Date(enquiry.celebrationDate).toISOString().slice(0, 10)
            : "",
          readyTime: enquiry.readyTime || "",
          status: enquiry.status || "",
          assignedExperts: enquiry.assignedExperts || [],
          whoIsCelebrating: enquiry.whoIsCelebrating || {
            name: "",
            phoneNumber: "",
            age: "",
            relationShip: "",
            gender: "",
          },
          area: enquiry.area || "",
          fullEnquiryObj: enquiry,
        }));

        setNotes(enquiry.notes || []);
        setSelectedTags(enquiry.tags || []);
        // Check if city or occasion should be "Other"
        if (enquiry.city && !arrContainsCity(cityOptions, enquiry.city)) {
          setIsCityOther(true);
        }
        if (!isPredefinedOccasion(enquiry.occasionName)) {
          setIsOccasionOther(true);
        }
      }
    } catch (error) {
      console.error("Error in onInitialLoad", error);
    }
  };

  const afterEnquirySave = () => {
    const enquiry = {
      ...states.fullEnquiryObj,
      tags: selectedTags,
      notes: notes,
      alternateNumbers: states.alternateNumbers || [],
      occasionName: states.occasionName || "",
      city: states.city || "",
      budget: states.budget || [0, 0],
      status: states.status || "",
      address: states.address,
      celebrationDate: states.celebrationDate
        ? moment(states.celebrationDate).toISOString()
        : "",
      readyTime: states.readyTime || "",
      assignedExperts: states.assignedExperts || [],
      whoIsCelebrating: states.whoIsCelebrating,
      area: states.area || "",
    };

    onEditCallBack(enquiry);
  };

  const arrContainsCity = (options, cityValue) =>
    options.some((opt) => opt.city === cityValue);

  // Occasions in a real app might be from a global store or static
  const predefinedOccasions = [
    { label: "Birthday", value: "birthday" },
    { label: "Anniversary", value: "anniversary" },
    { label: "Baby Shower", value: "baby_shower" },
    { label: "Welcome", value: "welcome" },
    { label: "Other", value: "other" },
  ];

  const isPredefinedOccasion = (occasion) =>
    predefinedOccasions.map((o) => o.value).includes(occasion);

  const handleClose = () => {
    setCompRenderStates((p) => ({
      ...p,
      enquiryEditModal: { isRender: false, enquiryData: null },
    }));
  };

  // Called whenever the user adds/edits/deletes notes
  const handleNotesUpdate = async (updatedNotesArray) => {
    // Perform your update logic. For example, call `editEnquiry`:
    const enquiryId = enquiryData?._id;
    if (!enquiryId) return;

    const response = await editEnquiryHelper().editEnquiry(enquiryId, {
      notes: updatedNotesArray,
    });

    if (response.isSuccess && response.enquiry) {
      // Optionally update your local state
      setNotes(updatedNotesArray); // The UI updates instantly
      // You could also update states if you rely on states.notes:
      setStates((prev) => ({ ...prev, notes: updatedNotesArray }));
      window.toast("Notes updated!", "success");
    } else {
      window.toast("Failed to update notes!", "error");
    }
  };

  if (states.isLoadingEnquiry) {
    return (
      <div className="fix-screen flex items-center justify-center ">
        <div
          onClick={handleClose}
          className="fixed inset-0 bg-black bg-opacity-50 z-[1]"
        ></div>
        <div className="z-[2] bg-white p-4 rounded shadow">
          <div className="loader w-[2rem]" />
        </div>
      </div>
    );
  }

  if (states.isEnquiryNotFound) {
    return (
      <div className="fix-screen flex items-center justify-center ">
        <div
          onClick={handleClose}
          className="fixed inset-0 bg-black bg-opacity-50 z-[1]"
        ></div>
        <div className="z-[2] bg-white p-4 rounded shadow text-center">
          <p className="text-red-500">Enquiry not found</p>
          <button
            className="mt-4 px-3 py-2 bg-gray-200 rounded"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  const cart = states?.userId?.cart?.cartId?.[0] || null;

  return (
    <div className="fix-screen flex items-start justify-start">
      {/* Overlay */}
      <div
        onClick={handleClose}
        className="fixed inset-0 bg-black bg-opacity-50 z-[1]"
      ></div>

      {/* Main Modal */}
      <div className="bg-white rounded-lg relative border text-black border-gray-200 flex-col z-[2] pt-[3rem] pb-[4rem] w-[30rem] max-w-[100dvw] h-[100dvh]">
        {/* Header */}
        <Header height="2.5rem" handleClose={handleClose} />

        {/* Scrollable Content */}
        <div className="w-full h-full overflow-auto px-2 py-2 text-sm space-y-4">
          <div className="flex items-center justify-between">
            <div className=" flex items-center gap-2 ">
              <ExpertSwitch
                enquiryId={enquiryData?._id}
                assignedExperts={states.assignedExperts}
                admins={admins}
                onUpdateAssignedExperts={(updated) => {
                  setStates((prev) => ({ ...prev, assignedExperts: updated }));
                }}
              />
              {/* CART icon if present */}
              {cart && (
                <div
                  onClick={() => {
                    setStates((prev) => ({ ...prev, isCartModalOpen: true }));
                  }}
                  className="blue-gradient size-[1.5rem] rounded-full flex-center text-2xs"
                >
                  <FaShoppingCart />
                </div>
              )}
            </div>

            <StatusPicker states={states} setStates={setStates} />
          </div>

          {/* USER INFO (Top: read-only) */}
          <UserHeader userId={states.userId} />

          <TagsManager
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            predefinedTags={enquiryUtils().predefinedEnquiryTags}
          />

          {/* ALTERNATE NUMBERS */}
          <AlternateNumbers states={states} setStates={setStates} />

          {/* OCCASION */}
          <OccasionField
            states={states}
            setStates={setStates}
            predefinedOccasions={predefinedOccasions}
            isOccasionOther={isOccasionOther}
            setIsOccasionOther={setIsOccasionOther}
          />

          {/* AREA */}
          <AreaField states={states} setStates={setStates} />

          {/* CITY */}
          <CityField
            states={states}
            setStates={setStates}
            cityOptions={cityOptions}
            isCityOther={isCityOther}
            setIsCityOther={setIsCityOther}
          />

          {/* BUDGET */}
          <BudgetField states={states} setStates={setStates} />

          <div className="flex-col">
            <label className="block text-xs font-medium text-gray-500">
              Celebration Date
            </label>

            <div className="flex w-full justify-between gap-4">
              {/* CELEBRATION DATE */}
              <CelebrationDateField states={states} setStates={setStates} />

              {/* READY TIME */}
              <ReadyTimeField states={states} setStates={setStates} />
            </div>
          </div>

          {/* WHO IS CELEBRATING */}
          <WhoIsCelebratingField states={states} setStates={setStates} />

          <div>
            <h3 className="text-sm font-semibold mb-3">Notes</h3>
            <Notes
              notes={notes}
              setNotes={setNotes}
              adminInfo={currentAdmin} // { _id, userName, etc. }
              onNotesUpdate={handleNotesUpdate}
            />
          </div>
        </div>

        {/* Bottom Section (Save / Create / Etc.) */}
        <Bottom
          height="2.75rem"
          states={states}
          setStates={setStates}
          selectedTags={selectedTags}
          afterEnquirySave={afterEnquirySave}
        />

        {states.isAddressSelectionModalOpen && (
          <AddressSelectMap
            {...{
              onClose: () => {
                setStates((p) => ({
                  ...p,
                  isAddressSelectionModalOpen: false,
                }));
              },
              address: states.address,
              callback: (address) => {
                setStates((p) => ({
                  ...p,
                  address,
                }));
              },
            }}
          />
        )}
        {states.isCartModalOpen && cart && (
          <CartModal
            {...{
              cart,
              onClose: () => {
                setStates((p) => {
                  return {
                    ...p,
                    isCartModalOpen: false,
                  };
                });
              },
            }}
          />
        )}
      </div>
    </div>
  );
}
