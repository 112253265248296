import moment from "moment";

import adminUtils from "../../../../utils/adminUtils";

const getUserDetails = (phoneNumber) => {
  return new Promise((resolve) => {
    window.v2Crud({
      onInitiate: () => {},
      onError: () => {
        resolve({ noUserFound: true, isError: true });
      },
      onSuccess: (res) => {
        const user = res.data?.[0]?.data?.[0];

        if (user) {
          resolve({
            noUserFound: false,
            user: { ...user, city: user?.defaultAddress?.city },
          });
        } else {
          resolve({ noUserFound: true });
        }
      },
      queries: [
        {
          modelName: "User",
          operation: "read",
          filter: { phoneNumber },
          selects: ["_id", "name", "email", "phoneNumber", "defaultAddress"],
        },
      ],
    });
  });
};

const handleAssignToMe = async ({
  states,
  setStates,
  expert,
  isAssigningToMe,
  callback = () => {},
  allAdmins,
}) => {
  try {
    // if (!states?.user || !states?.occasion || !states.city) {
    //   window.toast("Please fill all the fields", "error");
    //   return;
    // }

    let userId = states?.user?._id;

    if (!userId) {
      // phone number and name validation check
      if (!states?.user?.phoneNumber || !states?.user?.name) {
        window.toast("Please fill customer details", "error");
        return;
      }

      //  start creating a new user
      userId = adminUtils().convertPhoneNumberToCode(
        states?.user?.phoneNumber
      )?.code;

      if (!userId) {
        window.toast("Invalid phone number", "error");
        return;
      }

      const userInfo = {
        _id: userId,
        name: states?.user?.name,
        gender: states?.user?.gender || "male",
        defaultAddress: {
          city: states?.city,
        },
        phoneNumber: states?.user?.phoneNumber,
      };

      const createdUser = await createUser({ userData: userInfo });

      if (!createdUser) {
        window.toast("Unable to create user!", "error");
        return;
      }

      window.toast("User created successfully!");
    }

    if (!userId) {
      window.toast("User not found", "error");
      return;
    }

    const enquiryData = {
      userId,
      occasionName: states?.occasion,
      city: states?.city,
      assignedExperts: [
        {
          expert: expert._id,
          date: moment().toISOString(),
          tags: [],
        },
      ],
    };

    setStates({ ...states, isCreating: true });

    const createdEnquiry = await createEnquiry({ enquiryData });

    if (!createdEnquiry) {
      setStates({ ...states, isCreating: false });
      window.toast("Unable to create enquiry!", "error");
      return;
    }

    ///  updating the user's city and add new enquiry in to user's model

    let userUpdateObject = {
      $addToSet: {
        enquiries: createdEnquiry._id,
      },
      enquiry: createdEnquiry._id,
      name: states?.user?.name,
    };

    if (!states.user?.city) {
      // update the user's city with the selected city
      userUpdateObject["defaultAddress.city"] = states?.city;
    }

    const updatedUser = await updateUser({
      userId,
      updateObject: userUpdateObject,
    });

    if (!updatedUser) {
      window.toast("Unable to update user!", "error");
    }

    // user work done

    window.createNewEnquiry(false);

    if (isAssigningToMe) {
      window.editEnquiry(true, createdEnquiry);
    }

    let expertPopulatedEnquiryData = {
      ...createdEnquiry,
      assignedExperts: [
        {
          expert: allAdmins.find((curr) => curr._id === expert._id),
          date: moment().toISOString(),
          tags: [],
        },
      ],
    };

    callback({ isSuccess: true, enquiry: expertPopulatedEnquiryData });

    setStates({ ...states, isCreating: false });
  } catch (error) {
    console.error(error);
    window.toast("Something went wrong!", "error");
    setStates({ ...states, isCreating: false });
  }
};

const updateUser = ({ userId, updateObject }) => {
  return new Promise((resolve, reject) => {
    window.v2Crud({
      onError: (e) => {
        console.error(e);
        reject(e);
      },
      onSuccess: (res) => {
        const user = res.data?.[0]?.data;
        resolve(user);
      },
      queries: [
        {
          modelName: "User",
          operation: "update",
          filter: { _id: userId },
          update: updateObject,
        },
      ],
    });
  });
};

const createUser = ({ userData }) => {
  return new Promise((resolve, reject) => {
    window.v2Crud({
      onError: (e) => {
        console.error(e);
        reject(e);
      },
      onSuccess: (res) => {
        const user = res.data?.[0]?.data;

        resolve(user);
      },
      queries: [
        {
          modelName: "User",
          operation: "create",
          docs: userData,
        },
      ],
    });
  });
};

const createEnquiry = ({ enquiryData }) => {
  return new Promise((resolve, reject) => {
    window.v2Crud({
      onError: (e) => {
        console.error(e);
        reject(e);
      },
      onSuccess: (res) => {
        const enquiry = res.data?.[0]?.data;

        resolve(enquiry);
      },
      queries: [
        {
          modelName: "Enquiry",
          operation: "create",
          docs: enquiryData,
        },
      ],
    });
  });
};

export default function createNewEnquiryHelper() {
  return {
    getUserDetails,
    handleAssignToMe,
  };
}
