import moment from "moment";
import React from "react";
import clsx from "clsx";
import { FaAngleRight, FaRegCalendar } from "react-icons/fa";

let arrowButtonClickTimeOut;

export default function DateCarousel({
  eachRangeDaysCount = 1,
  onChangeCallback = (e) => console.log(e),
  rightArrowIcon,
  dateRange = [moment().startOf("day"), moment().endOf("day"), "Today"],
  size = "md", // "xs", "sm", "md", "lg"
}) {
  const onArrowClick = (arrow) => {
    if (arrowButtonClickTimeOut) clearTimeout(arrowButtonClickTimeOut);

    arrowButtonClickTimeOut = setTimeout(() => {
      let from, to;
      if (arrow === "left") {
        from = dateRange[0].subtract(eachRangeDaysCount, "days");
        to = dateRange[1].subtract(eachRangeDaysCount, "days");
      } else {
        from = dateRange[0].add(eachRangeDaysCount, "days");
        to = dateRange[1].add(eachRangeDaysCount, "days");
      }

      const label = getDateRangeLabel(from, to, eachRangeDaysCount);
      onChangeCallback([from, to, label]);
    }, 50);
  };

  const iconSizeMap = {
    xs: "size-4 text-[10px]",
    sm: "size-5 text-[12px]",
    md: "size-6 text-sm",
    lg: "size-8 text-base",
  };

  const paddingMap = {
    xs: "px-1 py-0.5 text-[10px]",
    sm: "px-2 py-1 text-[12px]",
    md: "px-3 py-1.5 text-sm",
    lg: "px-4 py-2 text-base",
  };

  const effectiveIcon = rightArrowIcon || (
    <div
      className={clsx(
        "flex items-center justify-center rounded-full bg-blue-100 text-blue-600 shadow-sm",
        iconSizeMap[size]
      )}
    >
      <FaAngleRight />
    </div>
  );

  const isSingleDay = dateRange[0].isSame(dateRange[1], "day");

  return (
    <div className="  whitespace-nowrap  justify-between bg-gradient-to-br from-white via-slate-50 to-slate-100 rounded-lg inline-flex items-center border border-slate-200">
      {isSingleDay && (
        <div
          className="rotate-180 text-ebo-blue cursor-pointer hover:scale-110 hover:bg-blue-50 p-1 rounded-full transition-all duration-150"
          onClick={() => onArrowClick("left")}
        >
          {effectiveIcon}
        </div>
      )}

      <div
        onClick={() =>
          window.setDateRange({
            type: "userAnalyticsCardDateRange",
            callBack: (r) => {
              onChangeCallback([r.from, r.to, r.label]);
            },
          })
        }
        className={clsx(
          "flex items-center gap-1 py-2 px-3 cursor-pointer",
          paddingMap[size]
        )}
      >
        <span className="text-slate-700 font-medium">{dateRange[2]}</span>
        <FaRegCalendar className="opacity-70 text-sm" />
      </div>

      {isSingleDay && (
        <div
          className="text-ebo-blue cursor-pointer hover:scale-110 hover:bg-blue-50 p-1 rounded-full transition-all duration-150"
          onClick={() => onArrowClick("right")}
        >
          {effectiveIcon}
        </div>
      )}
    </div>
  );
}

const getDateRangeLabel = (from, to, eachRangeDaysCount) => {
  let label;

  if (eachRangeDaysCount === 1) {
    label = from.format("Do MMM, YYYY");
  } else {
    label = `${from.format("Do MMM")} - ${to.format("Do MMM, YYYY")}`;
  }

  for (let predefinedLabel of predefinedLabels) {
    if (
      from.isSame(predefinedLabel[0], "day") &&
      to.isSame(predefinedLabel[1], "day")
    ) {
      label = predefinedLabel[2];
    }
  }

  return label;
};

const predefinedLabels = [
  [moment().startOf("day"), moment().endOf("day"), "Today"],
  [
    moment().subtract(1, "days").startOf("day"),
    moment().subtract(1, "days").endOf("day"),
    "Yesterday",
  ],
  [
    moment().add(1, "days").startOf("day"),
    moment().add(1, "days").endOf("day"),
    "Tomorrow",
  ],
];
