import React, { memo } from "react";
import { BiSolidOffer } from "react-icons/bi";
import useCartManager from "../../../../hooks/cart/useCartManager";

const SavingsStrip = ({ cart }) => {
  const { calculateAdvancedCartPricing } = useCartManager();

  const savings = calculateAdvancedCartPricing(cart).savings;

  return (
    <div className="py-2 text-sm rounded-md w-full flex-center gap-1 text-[#2136d4] bg-blue-50 ">
      <OfferIcon /> <div className="text-[#2136d4] font-medium">₹{savings}</div>{" "}
      saved on this service booking
    </div>
  );
};

const OfferIcon = () => {
  return <BiSolidOffer className="text-2xl mr-1 animate-pulse  " />;
};

export default memo(SavingsStrip);
