import { v2Api } from "../api/v2.api";
import { useStoreContext } from "../context/StoreContext";
const { isPointInPolygon, getCenter } = require("geolib");

export default function UseHelperFunctions() {
  const {
    storeStates: { serviceableCities, hubBoundaries: storeHubBoundaries },
    setStoreStates,
  } = useStoreContext();

  const getHubPolygons = async () => {
    try {
      let hubBoundaries = storeHubBoundaries || [];

      if (hubBoundaries?.length === 0) {
        const res = await v2Api({
          operation: "getHubBoundaries",
        });

        if (res.isSuccess) {
          hubBoundaries = res.data;
          setStoreStates((prev) => ({
            ...prev,
            hubBoundaries,
          }));
        }
      }

      return hubBoundaries;

      // extracting the hub polygons
    } catch (error) {
      console.error("Error in getHubBoundaries: ", error);
    }
  };

  const getHubIdForLocation = async (lat, lng) => {
    try {
      if (!lat || !lng) {
        return { hubId: null, hubs: [] };
      }
      const hubBoundaries = await getHubPolygons();

      const point = { latitude: lat, longitude: lng };
      let hubs = [];
      // Loop through each hub (feature) in the boundaries GeoJSON.
      for (const feature of hubBoundaries) {
        // feature: { polygon: [ [lat,lng], [lat,lng] ] , properties }
        // Assume the polygon is in feature.geometry.coordinates
        // GeoJSON coordinates are in [lng, lat] format.
        // We convert them into the format geolib expects: { latitude, longitude }
        if (!feature.polygon) continue;

        const polygonCoords = feature.polygon.map((coord) => ({
          latitude: coord[1],
          longitude: coord[0],
        }));
        // Check if the point is inside this polygon.
        if (isPointInPolygon(point, polygonCoords)) {
          hubs.push(feature.properties);
        }
      }

      // Sort hubs: serviceable ones first, then non-default hubs before default ones
      hubs.sort((a, b) => {
        if (a.isServiceable !== b.isServiceable) {
          return a.isServiceable ? -1 : 1;
        }
        if (a.isDefaultHub !== b.isDefaultHub) {
          return a.isDefaultHub ? 1 : -1;
        }
        return 0;
      });

      return { hubId: hubs[0]?.hubId || null, hubs };
    } catch (error) {
      console.error("Error in getHubIdForLocation: ", error);
    }
  };

  const getProductPriceForHub = async (product, hubId) => {
    try {
      const productPricing =
        product.listingPriceHubWise?.find((hubData) => hubData.hub === hubId) ||
        {};

      return {
        sellingPrice: productPricing.sellingPrice || 0,
        costPrice: productPricing.costPrice || 0,
        webDiscount: productPricing.webDiscount || 0,
      };
    } catch (error) {
      console.error("Error in getProductPriceForHub: ", error);
    }
  };

  const getCityOfHub = (hubId) => {
    let city;

    for (const currCity of Object.values(serviceableCities)) {
      if (currCity.hubIds.includes(hubId)) {
        city = currCity.city;
        break;
      }
    }

    return city;
  };

  const getAPointForAHub = async (hubId) => {
    try {
      if (!hubId) return null;
      const hubBoundaries = await getHubPolygons();
      // Find the hub that matches the provided hubId
      const hubFeature = hubBoundaries.find(
        (feature) => feature.properties.hubId === hubId
      );
      if (!hubFeature || !hubFeature.polygon) return null;

      // Convert polygon coordinates from [lng, lat] to { latitude, longitude }
      const polygonCoords = hubFeature.polygon.map((coord) => ({
        latitude: coord[1],
        longitude: coord[0],
      }));

      // Use geolib's getCenter to calculate the center point of the polygon
      const centerPoint = getCenter(polygonCoords);

      return { lat: centerPoint.latitude, lng: centerPoint.longitude };
    } catch (error) {
      console.error("Error in getAPointForAHub: ", error);
      return null;
    }
  };

  return {
    getCityOfHub,
    getHubPolygons,
    getHubIdForLocation,
    getProductPriceForHub,
    getAPointForAHub,
  };
}
