import React from "react";
import { BsSearch } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
let timeoutId;
export default function TextSearchBar({
  states,
  minSearchTextLengthToStartSearch,
  setStates,
  queryDebounceTime,
  autocompleteService,
  country,
}) {
  const debounce = (func, delay = queryDebounceTime) => {
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearch = (text) => {
    if (
      states.query < minSearchTextLengthToStartSearch ||
      text.length < minSearchTextLengthToStartSearch
    ) {
      setStates((p) => ({
        ...p,
        loadingPredictions: false,
        isPredictionsShow: false,
        showServiceCites: true,
      }));
      return;
    }
    setStates((p) => ({
      ...p,
      loadingPredictions: true,
      isPredictionsShow: true,
      showServiceCites: false,
    }));

    autocompleteService.getPlacePredictions(
      {
        input: states.query,
        origin: { lat: 0, lng: 0 },
        componentRestrictions: {
          country: country,
        },
      },
      handleSuggestions
    );
  };

  const debouncedSearch = debounce((text) => handleSearch(text));

  const handleSuggestions = (predictions, status) => {
    if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
      // setPredictions([]);
      console.log(" no predictions");
      return;
    }
    // setPredictions(predictions);
    setStates((p) => ({
      ...p,
      predictions: predictions,
      loadingPredictions: false,
    }));
  };

  return (
    <div className="relative z-[1] w-full max-w-[95%]">
      {/* Search Icon */}
      <BsSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-[1.2rem] text-gray-500 cursor-pointer" />

      {/* Search Input */}
      <input
        value={states.query}
        className="w-full rounded-lg border border-gray-300 bg-white text-gray-900 text-[1rem] px-12 py-3 outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 shadow-sm transition-all duration-200"
        placeholder="Search for an area, street, or city..."
        onChange={(e) => {
          setStates((p) => ({
            ...p,
            query: e.target.value,
            defaultUi: true,
            serviceAvailable: false,
            loadingPointLocation: false,
            loadingReverseGeoCode: false,
            noServiceAvailable: false,
          }));
          if (e.target.value.length >= minSearchTextLengthToStartSearch) {
            debouncedSearch(e.target.value);
          } else {
            clearTimeout(timeoutId);
            setStates((p) => ({
              ...p,
              predictions: [],
              isPredictionsShow: false,
            }));
          }
        }}
      />

      {/* Hint for typing more words */}
      {states.query &&
        states.query.trim().length < minSearchTextLengthToStartSearch && (
          <div className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 text-sm">
            Type {minSearchTextLengthToStartSearch - states.query.trim().length}{" "}
            more
          </div>
        )}

      {/* Clear Button */}
      {states.query.length > minSearchTextLengthToStartSearch &&
        !states.loadingPredictions && (
          <RxCross2
            onClick={() => {
              setStates((p) => ({
                ...p,
                query: "",
                predictions: [],
                defaultUi: true,
                loadingPredictions: false,
                isPredictionsShow: false,
                showServiceCites: true,
                loadingCurrentLocation: false,
                loadingPointLocation: false,
                loadingReverseGeoCode: false,
                serviceAvailable: false,
                noServiceAvailable: false,
                loadingLocation: false,
              }));
            }}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-[1.2rem] text-gray-500 cursor-pointer hover:text-gray-700 transition"
          />
        )}

      {/* Loading Indicator */}
      {states.loadingPredictions && (
        <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
          <div className="w-5 h-5 border-2 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
}
