import React, { useEffect, useState } from "react";
import SavingsStrip from "./c/SavingsStrip";
import DateAddress from "./c/DateAddress";
import ProductContainer from "./c/product/ProductContainer";
import BillSummary from "./c/BillSummary";
import useCartManager from "../../../hooks/cart/useCartManager";

export default function Cart({ cart: propCart }) {
  const [cart, setCart] = useState({});
  const { constructCart } = useCartManager();

  useEffect(() => {
    onInitialLoad();

    // eslint-disable-next-line
  }, [propCart]);

  const onInitialLoad = async () => {
    try {
      const constructedCart = await constructCart(propCart);

      setCart((prev) => ({
        ...prev,
        ...constructedCart,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex-col w-full pb-[3rem] space-y-3 px-3 h-full overflow-scroll">
      <div className="p-3 border-b rounded-b-xl shadow-sm w-full">
        <SavingsStrip cart={cart} />
      </div>
      <ProductContainer cart={cart} />
      <DateAddress cart={cart} />
      <BillSummary cart={cart} />
    </div>
  );
}
