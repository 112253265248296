import React, { useState } from "react";
import editEnquiryHelper from "../f/editEnquiryHelper";

export default function ExpertSelectModal({
  onClose,
  enquiryId,
  assignedExperts,
  admins,
  onUpdateAssignedExperts,
}) {
  const [selectedExpertId, setSelectedExpertId] = useState("");
  const [selectedExpert, setSelectedExpert] = useState(null);
  const [isAssigning, setIsAssigning] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleAssign = () => {
    if (!selectedExpertId) return;
    setIsAssigning(true);

    const newAssignedExperts = [
      ...assignedExperts,
      {
        expert: selectedExpert,
        date: new Date(),
      },
    ];

    const dbAssignedExperts = newAssignedExperts.map((ae) => ({
      expert: ae.expert._id,
      date: ae.date,
      tags: ae.tags || [],
    }));

    editEnquiryHelper()
      .editEnquiry(enquiryId, { assignedExperts: dbAssignedExperts })
      .then((res) => {
        if (res.isSuccess && res.enquiry) {
          onUpdateAssignedExperts(newAssignedExperts);
          onClose();
        }
      })
      .finally(() => {
        setIsAssigning(false);
      });
  };

  // Filter admins based on the search term matching name or phone using regex
  const filteredAdmins = admins.filter((adm) => {
    if (!searchTerm) return true;
    try {
      const regex = new RegExp(searchTerm, "i");
      return regex.test(adm.userName) || regex.test(adm.mobile);
    } catch (err) {
      // Fallback to simple string matching if regex fails
      return (
        adm.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        adm.mobile.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  });

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="relative z-10 bg-white w-[400px] rounded-lg shadow-lg"
      >
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-lg font-semibold text-gray-800">
            Select an Expert
          </h2>
          <button
            className="text-gray-500 hover:text-gray-800 transition"
            onClick={onClose}
          >
            ✕
          </button>
        </div>

        {/* Search Bar */}
        <div className="p-4">
          <input
            type="text"
            placeholder="Search by name or phone..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        {/* Expert List */}
        <div className="p-4 space-y-2 max-h-[300px] overflow-y-auto">
          {filteredAdmins.length > 0 ? (
            filteredAdmins.map((adm) => (
              <ExpertItem
                key={adm._id}
                admin={adm}
                isSelected={adm._id === selectedExpertId}
                onClick={() => {
                  setSelectedExpertId(adm._id);
                  setSelectedExpert(adm);
                }}
              />
            ))
          ) : (
            <p className="text-gray-500 text-sm">No agents found</p>
          )}
        </div>

        {/* Footer */}
        <div className="flex justify-end p-4 border-t">
          <button
            onClick={handleAssign}
            disabled={!selectedExpertId || isAssigning}
            className="bg-blue-600 text-white text-sm px-4 py-2 rounded-lg hover:bg-blue-700 disabled:opacity-50 transition"
          >
            {isAssigning ? "Assigning..." : "Assign Expert"}
          </button>
        </div>
      </div>
    </div>
  );
}

/** Improved ExpertItem with better UI */
function ExpertItem({ admin, isSelected, onClick }) {
  return (
    <div
      className={`flex items-center justify-between p-3 rounded-lg cursor-pointer transition ${
        isSelected ? "bg-blue-50 border border-blue-500" : "hover:bg-gray-100"
      }`}
      onClick={onClick}
    >
      <div className="flex items-center gap-3">
        <input
          type="radio"
          checked={isSelected}
          readOnly
          className="w-4 h-4 text-blue-600 accent-blue-600 cursor-pointer"
        />
        <div>
          <p className="font-medium text-gray-800">{admin.userName}</p>
          <p className="text-sm text-gray-500">{admin.mobile}</p>
        </div>
      </div>
      {isSelected && (
        <span className="text-xs font-medium text-blue-600">Selected</span>
      )}
    </div>
  );
}
