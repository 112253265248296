import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaCheck } from "react-icons/fa";

export default function CityField({
  states,
  setStates,
  cityOptions,
  isCityOther,
  setIsCityOther,
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const wrapperRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleCityChange = (value) => {
    if (value === "other") {
      setIsCityOther(true);
      setStates((prev) => ({ ...prev, city: "" }));
    } else {
      setIsCityOther(false);
      setStates((prev) => ({ ...prev, city: value }));
    }
    setShowDropdown(false);
  };

  return (
    <div className="space-y-2 relative" ref={wrapperRef}>
      {/* Label */}
      <label className="block text-xs font-semibold text-gray-600">City</label>

      {/* City Selection */}
      {!isCityOther ? (
        <div className="relative">
          <button
            type="button"
            onClick={() => setShowDropdown(!showDropdown)}
            className="w-full flex justify-between items-center border border-gray-300 bg-white px-3 py-2 rounded-md text-sm shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-blue-500 transition"
          >
            <span>
              {cityOptions.find((c) => c.city === states.city)?.label ||
                "Select City"}
            </span>
            <FaChevronDown className="text-gray-500 text-xs" />
          </button>

          {showDropdown && (
            <div className="absolute left-0 mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-20">
              {/* Search Bar */}
              <div className="p-2">
                <input
                  type="text"
                  placeholder="Search city..."
                  className="w-full px-3 py-1.5 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-blue-500"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
                />
              </div>

              {/* City List */}
              <div className="max-h-40 overflow-auto">
                {cityOptions
                  .filter((c) => c.label.toLowerCase().includes(searchTerm))
                  .map((c) => (
                    <div
                      key={c.city}
                      className={`flex justify-between items-center px-4 py-2 cursor-pointer text-sm hover:bg-gray-100 transition ${
                        states.city === c.city ? "bg-gray-200" : ""
                      }`}
                      onClick={() => handleCityChange(c.city)}
                    >
                      {c.label}
                      {states.city === c.city && (
                        <FaCheck className="text-blue-600 text-xs" />
                      )}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        // Custom City Input
        <div className="flex items-center gap-2">
          <input
            type="text"
            className="border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md px-3 py-2 text-sm flex-1 focus:outline-none transition"
            placeholder="Enter city"
            value={states.city}
            onChange={(e) =>
              setStates((prev) => ({ ...prev, city: e.target.value }))
            }
          />
          {/* Reset Button (Close "Other" Mode) */}
          <button
            type="button"
            className="text-gray-500 hover:text-red-500 transition p-2 rounded-md"
            onClick={() => {
              setIsCityOther(false);
              setStates((prev) => ({ ...prev, city: "" }));
            }}
          >
            ✕
          </button>
        </div>
      )}
    </div>
  );
}
