import UseHelperFunctions from "../UseHelperFunctions";

export default function useCartManager() {
  const { getHubIdForLocation } = UseHelperFunctions();

  /**
   * Constructs and updates a shopping cart object based on provided changes and user information.
   *
   * This function modifies the cart state by merging new key-value pairs into the existing cart. If the cart already has an updated user
   * object, that user is used; otherwise, it integrates the provided user details (excluding sensitive tokens) to form a unique cart identifier.
   * It also handles special cases such as transforming offers into the required object structure and ensures default values for essential fields.
   * Additionally, the function calculates pricing based on the product price, add-ons, and coupon discounts before persisting the updated cart in local storage.
   *
   * @param {Array<Array<any>>} [changedKeyValues=[]] - A list of key-value pairs representing modifications to apply to the cart.
   *   If the key is 'offers' and the value array contains offer objects, it ensures the proper structure is maintained.
   * @param {Object} [propUser=user] - The user object used for cart association if the cart does not already include an updated user.
   *   The provided user object is filtered to exclude sensitive tokens. If a user is already present in the cart state, that user is retained.
   *
   * @returns {Object} The updated cart object. Returns an empty object if the cart does not contain a product.
   *
   * @throws {Error} Logs any errors encountered during the cart construction process.
   */
  const constructCart = async (cart) => {
    try {
      let tempCart = { ...cart };

      // Ensure required fields have default values
      tempCart.addOns = tempCart.addOns || [];
      tempCart.offers = tempCart.offers || [];
      tempCart["santaTip"] = tempCart.santaTip || 0;

      if (!tempCart?.hubId && !tempCart?.bookingAddress) {
        const { lat, lng } = cart?.bookingAddress?.locationv2 || {};
        const { hubId } = await getHubIdForLocation(lat, lng);

        tempCart["hubId"] = hubId;
      }

      let cartPricing = calculateAdvancedCartPricing(tempCart);

      tempCart["buyingPrice"] = cartPricing.buyingPrice;

      tempCart["cartPrice"] = cartPricing.cartPrice;

      if (cartPricing?.couponDiscount === 0) {
        tempCart["offers"] = [];
        cartPricing = calculateAdvancedCartPricing(tempCart);
      }

      // any changes for the cart will pass through this function
      localStorage.setItem("cart", JSON.stringify(tempCart));

      return tempCart;
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Calculates an advanced breakdown of all pricing details for the current cart.
   *
   * This function computes:
   *  1. addonsSellingPrice: Total selling price for all add-ons.
   *  2. addonsCostPrice: Total cost price for all add-ons.
   *  3. couponDiscount: Discount applied from the coupon on the product selling price.
   *  4. productSellingPrice: Selling price of the product for the selected hub.
   *  5. productCostPrice: Cost price of the product for the selected hub.
   * *  productWebDiscount: Web discount applied on the product.
   *  6. convenienceFee: The fee applied for convenience (defaults to 0 if not present).
   *  7. santaTip: Additional tip (defaults to 0 if not set).
   *  8. cartPrice: Sum of addonsCostPrice, productCostPrice, and convenienceFee.
   *  9. buyingPrice: Sum of addonsSellingPrice, productSellingPrice, convenienceFee minus couponDiscount.
   * 10. amountToPay: buyingPrice plus santaTip.
   * 11. advancePrepayDiscount: Discount calculated for advance prepayment.
   * 12. fullPrepayDiscount: Discount calculated for full prepayment.
   * 13. savings: Total savings computed as couponDiscount + (productCostPrice - productSellingPrice) + (addonsCostPrice - addonsSellingPrice).
   * 14. couponValidationPrice: The total price used for coupon validation (productSellingPrice + addonsSellingPrice).
   *
   * Note: This function assumes that `cart`, `cartHubId`, and helper functions such as
   *       `cartUtils().calculateCouponDiscount` and `calculate_prepaid_discount` are available in the current scope.
   *
   * @returns {Object} An object containing all the computed pricing details.
   */
  const calculateAdvancedCartPricing = (cart) => {
    try {
      // If there is no valid cart or product, return default pricing details.
      if (!cart || !cart.product || !cart.hubId) {
        return {
          addonsSellingPrice: 0,
          addonsCostPrice: 0,
          couponDiscount: 0,
          productSellingPrice: 0,
          productCostPrice: 0,
          convenienceFee: 0,
          santaTip: 0,
          cartPrice: 0,
          buyingPrice: 0,
          amountToPay: 0,
          advancePrepayDiscount: 0,
          fullPrepayDiscount: 0,
          savings: 0,
          coupon: null,
          couponValidationPrice: 0,
          discountIncludingCoupon: 0,
        };
      }

      const cartHubId = cart.hubId;

      const productPricing = getProductPricing(
        cart.product?.listingPriceHubWise,
        cartHubId
      );

      const productSellingPrice = productPricing.sellingPrice || 0;
      const productCostPrice = productPricing.costPrice || 0;
      const productWebDiscount = productPricing.webDiscount || 0;

      // Initialize addon pricing values.
      let addonsSellingPrice = 0;
      let addonsCostPrice = 0;

      // Loop through all add-ons in the propCart.
      if (Array.isArray(cart.addOns)) {
        cart.addOns.forEach((addonItem) => {
          const addonPriceDetail = getProductPricing(
            addonItem.addon?.listingPriceHubWise,
            cartHubId
          );
          if (addonPriceDetail) {
            addonsSellingPrice +=
              addonPriceDetail.sellingPrice * addonItem.quantity;
            addonsCostPrice += addonPriceDetail.costPrice * addonItem.quantity;
          }
        });
      }

      // Retrieve convenienceFee and santaTip, defaulting to 0 if they aren't defined.
      const convenienceFee = cart.convenienceFee || 0;
      const santaTip = cart.santaTip || 0;

      const couponValidationPrice = productSellingPrice + addonsSellingPrice;

      // Calculate coupon discount using a helper from cartUtils.
      const couponDiscount =
        getCouponDiscount(
          cart.offers?.[0],
          productSellingPrice,
          couponValidationPrice
        ) || 0;

      // Calculate overall cart and buying prices.
      const cartPrice = addonsCostPrice + productCostPrice + convenienceFee;
      const buyingPrice =
        addonsSellingPrice +
        productSellingPrice +
        convenienceFee -
        couponDiscount;

      // Calculate the final amount the user needs to pay.
      const amountToPay = buyingPrice + santaTip;

      // Calculate prepaid discounts using an external helper function.
      const advancePrepayDiscount = getPrepaidDiscount(buyingPrice, "advance");

      const fullPrepayDiscount = getPrepaidDiscount(buyingPrice, "full");

      const discountIncludingCoupon = Math.round(
        // discount in percentage: web discount + coupon discount
        ((parseInt(productCostPrice) -
          (parseInt(productSellingPrice) - parseInt(couponDiscount || 0))) *
          100) /
          parseInt(productCostPrice)
      );

      // Calculate total savings.
      const savings =
        couponDiscount +
        (productCostPrice - productSellingPrice) +
        (addonsCostPrice - addonsSellingPrice);

      // Return the computed pricing breakdown.
      return {
        addonsSellingPrice,
        addonsCostPrice,
        couponDiscount,
        productSellingPrice,
        productCostPrice,
        productWebDiscount,
        convenienceFee,
        santaTip,
        cartPrice,
        buyingPrice,
        amountToPay,
        advancePrepayDiscount,
        fullPrepayDiscount,
        savings,
        coupon: cart?.offers?.[0]?.offer || null,
        offerCode: cart?.offers?.[0]?.offer?.offerCode,
        couponValidationPrice,
        discountIncludingCoupon,
      };
    } catch (error) {
      console.error("Error calculating advanced cart pricing:", error);
      return {};
    }
  };

  // Extract product pricing for the selected hub.
  const getProductPricing = (listingPriceHubWise, hubId) => {
    return listingPriceHubWise?.find((hubData) => hubData.hub === hubId) || {};
  };

  const checkOfferEligibility = (coupon, price) => {
    // For regular offers, check the minimum order value.
    if (price >= coupon.minOrderValue) {
      return { valid: true };
    }

    // Check for range2 offers first.
    if (coupon.isRange2) {
      if (price < coupon.range2MinOrderValue) {
        return {
          valid: false,
          moneyNeeded: coupon.range2MinOrderValue - price,
        };
      }
      if (price > coupon.range2MaxOrderValue) {
        // Price exceeds the allowed range; depending on your business rules,
        // you might mark this as invalid or just ignore the extra amount.
        return { valid: false };
      }
      return { valid: true };
    }

    return { valid: false, moneyNeeded: coupon.minOrderValue - price };
  };

  const getCouponDiscount = (offer, price, validationCheckPrice = 0) => {
    const coupon = offer?.offer || offer;
    if (!coupon) return 0;

    const eligibility = checkOfferEligibility(
      coupon,
      validationCheckPrice || price
    );

    if (!eligibility.valid) {
      // If the offer isn’t eligible, no discount is applied.
      return 0;
    }

    let offerDiscount = 0;
    const { discountType, discountValue, maxOfferAmountGain } = coupon;

    if (discountType === 0) {
      // Percentage discount for normal offers
      const discount = Math.round((price * discountValue) / 100);
      offerDiscount = Math.min(discount, maxOfferAmountGain);
    } else {
      // Amount discount for normal offers
      offerDiscount = discountValue;
    }

    if (offerDiscount > 0) {
      return offerDiscount;
    } else if (coupon.isRange2) {
      if (coupon.range2DiscountType === 0) {
        // Percentage discount for range2
        const discount = Math.round((price * coupon.range2DiscountValue) / 100);
        offerDiscount = Math.min(discount, coupon.range2MaxPriceGain);
      } else {
        // Amount discount for range2
        offerDiscount = discountValue;
      }
    }

    return offerDiscount;
  };

  const getPrepaidDiscount = (price, type) => {
    let discountPrice = 0;

    if (type === "advance") {
      if (price >= 1599) {
        discountPrice = Math.round(
          (price * 3) / 100 > 75 ? 75 : (price * 3) / 100
        );
      }
      if (price >= 1299 && price < 1599) {
        discountPrice = Math.round((price * 3) / 100);
      }
      if (price < 1299) {
        discountPrice = Math.round((price * 2) / 100);
      }
    } else {
      if (price >= 1599) {
        discountPrice = Math.round(
          (price * 5) / 100 > 150 ? 150 : (price * 5) / 100
        );
      }
      if (price >= 1299 && price < 1599) {
        discountPrice = Math.round((price * 5) / 100);
      }
      if (price < 1299) {
        discountPrice = Math.round((price * 3) / 100);
      }
    }

    return discountPrice;
  };

  const getFormattedAddress = (cart) => {
    try {
      const address = cart?.bookingAddress;

      return {
        line1: address?.address1,
        line2: `${address?.address2 && address?.address2 + ", "} ${
          address?.locationv2?.addressLine1 || ""
        }`,
        name: address?.receiversDetails?.name,
        mobileNumber: address?.receiversDetails.mobileNumber,
        addressLabel: address?.addressLabel,
        isSavedAddress: true,
      };
    } catch (error) {
      console.error(error);
      return {
        line1: "",
        line2: "",
      };
    }
  };

  return {
    // methods
    constructCart,
    calculateAdvancedCartPricing,
    getFormattedAddress,
    getProductPricing,
  };
}
