import moment from "moment";

const CART_PRODUCT_SELECT =
  "_id name listingPriceHubWise tags images ocassionCategory";
const CART_COUPON_SELECT =
  "_id discountType discountValue maxOfferAmountGain isRange2 minOrderValue offerCode range2DiscountType range2DiscountValue range2MaxPriceGain range2MinOrderValue";
const CART_FREEBIE_SELECT =
  " _id category description image price name discountedPrice ";
const CART_ADDON_SELECT =
  "_id addonImage addonName category listingPriceHubWise tags description isColor isText";
const CART_USER_SELECT = "name phoneNumber _id gender createdAt ";

const getEnquiryDetails = (enquiryId) => {
  return new Promise((resolve) => {
    window.v2Crud({
      onError: (e) => {
        console.error(e);
        window.toast("Something went wrong!", "error");
        resolve({ isError: true, isEnquiryNotFound: true });
      },
      onSuccess: (res) => {
        const enquiry = res.data?.[0]?.data?.[0];
        if (enquiry) {
          console.log(enquiry.userId);
          resolve({ enquiry, isEnquiryNotFound: false });
        } else {
          resolve({ isEnquiryNotFound: true });
        }
      },
      queries: [
        {
          modelName: "Enquiry",
          operation: "read",
          filter: { _id: enquiryId },
          populates: [
            {
              path: "userId",
              select:
                " _id name phoneNumber defaultAddress gender cart createdAt",
              model: "User",
              populate: [
                {
                  path: "cart.cartId",
                  model: "Cart",
                  populate: [
                    {
                      path: "product",
                      model: "Product",
                      select: CART_PRODUCT_SELECT,
                    },
                    {
                      path: "addOns.addon",
                      model: "Addon",
                      select: CART_ADDON_SELECT,
                    },
                    {
                      path: "offers.offer",
                      model: "Offer",
                      select: CART_COUPON_SELECT,
                    },
                    {
                      path: "bookingAddress",
                      model: "Addresses",
                    },
                    {
                      path: "freebie",
                      model: "Freebie",
                      select: CART_FREEBIE_SELECT,
                    },
                    {
                      path: "user",
                      model: "User",
                      select: CART_USER_SELECT,
                    },
                  ],
                },
              ],
            },
            {
              path: "assignedExperts.expert",
              select: "userName _id mobile accountStatus",
              model: "Admin",
            },
            {
              path: "notes.admin",
              select: "userName _id mobile accountStatus",
              model: "Admin",
            },
          ],
        },
      ],
    });
  });
};

const handleEnquirySave = async ({
  states,
  setStates,
  selectedTags,
  callback,
}) => {
  try {
    // Set a loading indicator so we don’t allow multiple saves
    setStates((prev) => ({
      ...prev,
      isSavingEnquiry: true,
    }));

    // Typically you'll have states._id or something that identifies the Enquiry
    const enquiryId = states._id;
    if (!enquiryId) {
      window.toast("No Enquiry ID found!", "error");
      return;
    }

    // Build an updateObj from states fields + selectedTags
    // (Adjust to match your real fields)
    const updateObj = {
      tags: selectedTags,
      alternateNumbers: states.alternateNumbers || [],
      occasionName: states.occasionName || "",
      city: states.city || "",
      status: states.status || "",
      budget: states.budget || [0, 0],
      celebrationDate: states.celebrationDate
        ? moment(states.celebrationDate).toISOString()
        : "",
      address: states.address,
      readyTime: states.readyTime || "",
      assignedExperts: states.assignedExperts || [],
      whoIsCelebrating: states.whoIsCelebrating,
      area: states.area || "",
    };

    // Now call editEnquiry or v2Crud directly
    const res = await editEnquiry(enquiryId, updateObj);

    if (res.isSuccess) {
      window.toast("Enquiry updated successfully!", "success");
      callback();
      window.editEnquiry(false);
    } else {
      window.toast("Failed to update enquiry!", "error");
    }
  } catch (error) {
    console.error(error);
    window.toast("Something went wrong!", "error");
  } finally {
    // Always reset loading
    setStates((prev) => ({
      ...prev,
      isSavingEnquiry: false,
    }));
  }
};

const editEnquiry = (enquiryId, updateObj) => {
  return new Promise((resolve) => {
    window.v2Crud({
      onError: (e) => {
        console.error(e);
        window.toast("Something went wrong!", "error");
        resolve({ isError: true, isSuccess: false });
      },
      onSuccess: (res) => {
        const enquiry = res.data?.[0]?.data;

        resolve({ isSuccess: true, enquiry });
      },
      queries: [
        {
          modelName: "Enquiry",
          operation: "update",
          filter: { _id: enquiryId },
          update: updateObj,
        },
      ],
    });
  });
};

export default function editEnquiryHelper() {
  return {
    getEnquiryDetails,
    editEnquiry,
    handleEnquirySave,
  };
}
