import React, { useState, useRef, useEffect } from "react";
import {
  FaUser,
  FaPhoneAlt,
  FaBirthdayCake,
  FaUsers,
  FaVenusMars,
} from "react-icons/fa";

export default function WhoIsCelebratingField({ states, setStates }) {
  const { name, phoneNumber, age, relationShip, gender } =
    states.whoIsCelebrating;
  const [isCustomRelationship, setIsCustomRelationship] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const wrapperRef = useRef(null);

  const predefinedRelationships = [
    "Son",
    "Father",
    "Mother",
    "Girlfriend",
    "Boyfriend",
    "Friend",
    "Brother",
    "Sister",
    "Wife",
    "Husband",
    "Other",
  ];

  const handleChange = (field, value) => {
    setStates((prev) => ({
      ...prev,
      whoIsCelebrating: {
        ...prev.whoIsCelebrating,
        [field]: value,
      },
    }));
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="space-y-2">
      {/* Label */}
      <label className="block text-xs font-semibold text-gray-600">
        Who is Celebrating?
      </label>

      {/* Input Fields */}
      <div className="flex flex-col gap-2">
        {/* Name */}
        <div className="relative">
          <FaUser className="absolute left-3 top-3 text-gray-500 text-xs" />
          <input
            type="text"
            className="border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md px-8 py-2 text-sm w-full shadow-sm transition"
            placeholder="Name"
            value={name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </div>

        {/* Phone Number */}
        <div className="relative">
          <FaPhoneAlt className="absolute left-3 top-3 text-gray-500 text-xs" />
          <input
            type="text"
            className="border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md px-8 py-2 text-sm w-full shadow-sm transition"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => {
              const val = e.target.value;
              if (/^\d{0,10}$/.test(val)) {
                handleChange("phoneNumber", val);
              }
            }}
          />
        </div>

        {/* Age & Gender in One Line */}
        <div className="flex gap-2">
          {/* Age */}
          <div className="relative flex-1">
            <FaBirthdayCake className="absolute left-3 top-3 text-gray-500 text-xs" />
            <input
              type="number"
              className="border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md px-8 py-2 text-sm w-full shadow-sm transition"
              placeholder="Age"
              value={age || ""}
              onChange={(e) => handleChange("age", e.target.value)}
            />
          </div>

          {/* Gender */}
          <div className="relative flex-1">
            <FaVenusMars className="absolute left-3 top-3 text-gray-500 text-xs" />
            <select
              className="border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md px-8 py-2 text-sm w-full shadow-sm transition bg-white"
              value={gender || ""}
              onChange={(e) => handleChange("gender", e.target.value)}
            >
              <option value="">Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>

        {/* Relationship (Predefined Dropdown + Other Input) */}
        <div className="relative" ref={wrapperRef}>
          <FaUsers className="absolute left-3 top-3 text-gray-500 text-xs" />
          {!isCustomRelationship ? (
            <button
              type="button"
              onClick={() => setShowDropdown(!showDropdown)}
              className="border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md px-8 py-2 text-sm w-full text-left bg-white shadow-sm transition flex justify-between"
            >
              {relationShip || "Select Relationship"}
              <span className="text-gray-500">&#9662;</span>
            </button>
          ) : (
            <input
              type="text"
              className="border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md px-8 py-2 text-sm w-full shadow-sm transition"
              placeholder="Enter Relationship"
              value={relationShip}
              onChange={(e) => handleChange("relationShip", e.target.value)}
            />
          )}

          {showDropdown && (
            <div className="absolute left-0 mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-20">
              {predefinedRelationships.map((rel) => (
                <div
                  key={rel}
                  className="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100 transition"
                  onClick={() => {
                    if (rel === "Other") {
                      setIsCustomRelationship(true);
                      handleChange("relationShip", "");
                    } else {
                      setIsCustomRelationship(false);
                      handleChange("relationShip", rel);
                    }
                    setShowDropdown(false);
                  }}
                >
                  {rel}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
