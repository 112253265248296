import moment from "moment";
import dateUtils from "./adminUtils/date/dateUtils";

const geolib = require("geolib");

export const CURRENCY_SYMBOL = "₹";

// Utility Functions

/**
 * Format a number with commas as thousand separators.
 * @param {number|string} num - The number to format.
 * @returns {string} The formatted number with commas.
 */
const formatNumberWithCommas = (num) => {
  if (num === null || num === undefined) return "";
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Format a number as currency.
 * @param {number} amount - The amount to format.
 * @param {string} currencySymbol - The currency symbol to use.
 * @returns {string} The formatted currency string.
 */
const formatCurrency = (amount, currencySymbol = CURRENCY_SYMBOL) => {
  return `${currencySymbol}${formatNumberWithCommas(amount)}`;
};

const escapeRegex = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const JSONVieweThemes = {
  whiteTheme: {
    scheme: "custom-white-background",
    author: "custom",
    base00: "#ffffff", // White background
    base01: "#f0f0f0", // Slightly darker for subtle contrast
    base02: "#d8d8d8", // Light gray for secondary elements
    base03: "#a0a0a0", // Medium gray for comments, meta
    base04: "#707070", // Dark gray for faint text
    base05: "#333333", // Darker text for better contrast on white
    base06: "#292929", // Even darker for strong emphasis
    base07: "#1d1f21", // Near-black for primary text
    base08: "#e74c3c", // Red for errors, warnings
    base09: "#e67e22", // Orange for highlights, warnings
    base0A: "#f1c40f", // Yellow for numbers, identifiers
    base0B: "#127912", // Green for success, booleans
    base0C: "#1abc9c", // Cyan for special elements, code
    base0D: "#3498db", // Blue for keywords, links
    base0E: "#9b59b6", // Purple for constants, decorators
    base0F: "#e74c3c", // Red for errors
  },
};

const rootAdmins = ["GCM79UV89E", "IBF62QT72I", "JHI92XS12B", "GCN52UX72H"];
const devAdmins = ["GCM79UV89E", "GCN52UX72H"];

const convertPhoneNumberToCode = (phoneNumber) => {
  if (phoneNumber.length !== 10) {
    return {
      isSuccess: false,
      code: "null",
      isError: false,
      message: "invalid phone Number",
    };
  }
  const first = String.fromCharCode(parseInt(phoneNumber[0]) + 65);
  const second = String.fromCharCode(parseInt(phoneNumber[1]) + 65);
  const tenth = String.fromCharCode(parseInt(phoneNumber[9]) + 65);
  const third = String.fromCharCode(parseInt(phoneNumber[2]) + 69);
  const sixth = String.fromCharCode(parseInt(phoneNumber[5]) + 81);
  const seventh = String.fromCharCode(parseInt(phoneNumber[6]) + 81);

  const FinalStringFormate =
    first +
    second +
    third +
    phoneNumber[3] +
    phoneNumber[4] +
    sixth +
    seventh +
    phoneNumber[7] +
    phoneNumber[8] +
    tenth;

  return {
    isSuccess: true,
    code: FinalStringFormate,
    isError: false,
    message: "",
  };
};

/**
 * Given a set of points, finds all points within a given radius.
 * @param {number} lat - The latitude of the center point.
 * @param {number} lng - The longitude of the center point.
 * @param {Object[]} points - The array of points to search through.
 * @param {number} radius - The radius to search within in meters.
 * @returns {Object[]} An array of points that are within the given radius.
 *  Each point will have a `distance` property that shows how far it is from the center point.
 */
function findPointsWithinRadius({ lat, lng, points, radius }) {
  try {
    let pointsWithinRadius = [];

    for (const currPoint of points) {
      if (!currPoint.lat || !currPoint.lng) return false;

      const distance = geolib.getDistance(
        { latitude: lat, longitude: lng },
        { latitude: currPoint.lat, longitude: currPoint.lng }
      );

      if (distance <= radius) {
        pointsWithinRadius.push({ ...currPoint, distance: distance });
      }
    }

    return pointsWithinRadius;
  } catch (error) {
    console.error(error);
  }
}

const santaTags = [
  "team member",
  "backup santa",
  "ekyc pending",
  "ekyc in review",
  "team eligible",
  "helper",
  "e-agent", // ebo agent
];

const getOrderSlotDetails = (order) => {
  const arrivingTime = parseInt(order.date?.delivery?.arrivingTime || 0);
  const readyTime = parseInt(order.date?.delivery?.readyTime || 0);

  let markerIconName = "";
  let slotLabel = "";

  if (arrivingTime >= 6 && arrivingTime <= 12) {
    markerIconName = "MorningSunIcon.png";
    slotLabel = "Morning";
  } else if (arrivingTime > 12 && arrivingTime <= 16) {
    markerIconName = "AfternoonSunIcon.png";
    slotLabel = "Afternoon";
  } else if (arrivingTime > 16 && arrivingTime <= 19) {
    markerIconName = "EveningSunIcon.png";
    slotLabel = "Evening";
  } else if (arrivingTime > 19) {
    markerIconName = "NightMoonIcon.png";
    slotLabel = "Night";
  } else if (arrivingTime > 0 && arrivingTime <= 6) {
    markerIconName = "MidnightMoonIcons.png";
    slotLabel = "Midnight";
  }

  return {
    sunIconUrl: `https://img.ebonow.com/icons/3d/${markerIconName}`,
    celebrationTime: moment(order.date?.delivery?.celebrationDate),
    readyTime,
    arrivingTime,
    slotLabel,
    slot:
      moment(order.date?.delivery?.arrivingTime, "HH").format("hh:mm A") +
      " - " +
      moment(order.date?.delivery?.readyTime, "HH").format("hh:mm A"),
  };
};

/**
 * Checks if an image URL is valid/corrupt by attempting to load it.
 * @param {string} url - The image URL to test
 * @returns {Promise<boolean>} - Resolves with true if valid, false if invalid/corrupt
 */
function isImageUrlValid(url) {
  return new Promise((resolve) => {
    if (!url || typeof url !== "string") {
      resolve(false);
      return;
    }

    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
}

export {
  formatCurrency,
  rootAdmins,
  formatNumberWithCommas,
  JSONVieweThemes,
  devAdmins,
  convertPhoneNumberToCode,
  escapeRegex,
  santaTags,
  findPointsWithinRadius,
  getOrderSlotDetails,
};

export default function adminUtils() {
  return {
    formatCurrency,
    rootAdmins,
    formatNumberWithCommas,
    JSONVieweThemes,
    devAdmins,
    convertPhoneNumberToCode,
    escapeRegex,
    santaTags,
    findPointsWithinRadius,
    getOrderSlotDetails,
    isImageUrlValid,
    analyzeDateDifference: dateUtils().analyzeDateDifference,
  };
}
