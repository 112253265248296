import React from "react";
import useCartManager from "../../../../../../../hooks/cart/useCartManager";

const RIGHT_PART_WIDTH = 80; // PX
const ADDON_IMAGE_WIDTH = 50; // PX

export default function HorizontalAddonCard({ addon, hubId }) {
  const { getProductPricing } = useCartManager();
  if (!addon) return null;
  // ----------------  destructure ------------------------------
  const {
    quantity,
    text,
    isFreebie,
    addon: { price, addonName, addonImage, listingPriceHubWise },
  } = addon;

  const pricing = getProductPricing(listingPriceHubWise, hubId);

  return (
    <div className=" w-full flex-shrink-0 max-w-full  flex ">
      {/* addon image and name and price */}
      <div
        style={{
          width: `calc(100% - ${RIGHT_PART_WIDTH}px)`,
        }}
        className="flex  flex-1  items-start gap-1.5"
      >
        <img
          src={addonImage}
          alt=""
          srcSet=""
          style={{
            width: ADDON_IMAGE_WIDTH,
          }}
          className="  flex-shrink-0 p-1 border rounded-lg  aspect-square "
        />

        <div
          style={{
            width: `calc(100% - ${ADDON_IMAGE_WIDTH}px)`,
          }}
          className="flex-col items-start max-w-full pt-0.5 pb-0.5 "
        >
          <span className=" whitespace-nowrap text-ellipsis w-full overflow-hidden text-sm font-[600] ">
            {addonName}
          </span>
          <div className=" flex items-center gap-1 ">
            <span className=" text-xs text-500 font-medium text-gray-500 ">
              x{quantity}
            </span>
            {text && (
              <div className="text-xs flex items-center whitespace-nowrap flex-nowrap">
                <span className=" p-[2px] rounded-full blue-gradient mr-1 "></span>

                <span>Added - </span>
                <span
                  className=" text-xs text-500 font-medium ml-1
              border-b border-dotted uppercase whitespace-nowrap border-[#2136d4] text-[#2136d4]"
                >
                  {text}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          width: RIGHT_PART_WIDTH,
        }}
        className="flex-col items-end relative gap-1 pt-0.5 flex-shrink-0"
      >
        <div className="flex items-center gap-1 text-xs">
          <span className="text-end line-through font-medium text-gray-400">
            ₹{parseInt(pricing.costPrice || price) * parseInt(quantity)}
          </span>
          <span className=" text-end font-semibold text-black">
            {isFreebie ? (
              "FREEE"
            ) : (
              <> ₹{parseInt(pricing.sellingPrice) * parseInt(quantity)}</>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
