import React, { useEffect, useMemo, useState } from "react";
import Header from "./c/Header";
import Bottom from "./c/Bottom";
import createNewEnquiryHelper from "./f/createNewEnquiryHelper";
import { useStoreContext } from "../../../context/StoreContext";
import { IoMdClose } from "react-icons/io";
import { useAdminContext } from "../../../context/AdminContext";

export default function CreateNewEnquiryModal({
  compRenderStates: { enquiryCreateModal },
  setCompRenderStates,
}) {
  const {
    storeStates: { serviceableCities },
  } = useStoreContext();

  const {
    adminState: { adminInfo },
  } = useAdminContext();

  // Predefined occasions
  const predefinedOccasions = useMemo(
    () => [
      { label: "Birthday", value: "birthday" },
      { label: "Anniversary", value: "anniversary" },
      { label: "Baby Shower", value: "baby_shower" },
      { label: "Welcome", value: "welcome" },
      { label: "Other", value: "other" },
    ],
    []
  );

  // Convert serviceableCities object into array + add an Other option
  // e.g. serviceableCities might be { bangalore: { city: "Bangalore", label: "Bangalore" }, ...}
  // Adjust how you map them if your structure is different.
  const cityOptions = useMemo(() => {
    const arr = Object.values(serviceableCities); // -> [ { city: "Bangalore", label: "Bangalore" }, ... ]
    return [
      ...arr,
      { city: "other", label: "Other" }, // We'll treat city = "other" for the 'Other' choice
    ];
  }, [serviceableCities]);

  const [states, setStates] = useState({
    isCreating: false,
    phoneNumber: enquiryCreateModal?.phoneNumber || "",
    user: null,
    occasion: "",
    city: "",
    loadingUser: false,
    noUserFound: false,
    assignTo: enquiryCreateModal?.options?.assignTo || adminInfo?._id, // admin ID
  });

  // We'll track whether city is "Other" or not
  const [isCityOther, setIsCityOther] = useState(false);

  // We'll track whether occasion is "Other" or not
  const [isOccasionOther, setIsOccasionOther] = useState(false);

  // We'll track gender separately
  const [gender, setGender] = useState("");

  const handleClose = () => {
    setCompRenderStates((p) => ({
      ...p,
      enquiryCreateModal: { isRender: false },
    }));
  };

  useEffect(() => {
    // If phoneNumber is exactly 10 digits, fetch user details.
    if (states.phoneNumber.length === 10) {
      setStates((prev) => ({
        ...prev,
        loadingUser: true,
        noUserFound: false,
        user: null,
      }));

      createNewEnquiryHelper()
        ?.getUserDetails(states.phoneNumber)
        .then((user) => {
          if (user.noUserFound) {
            setStates((prev) => ({
              ...prev,
              loadingUser: false,
              noUserFound: true,
              user: { phoneNumber: states.phoneNumber, name: "", city: "" },
              city: "",
            }));
          } else {
            setStates((prev) => ({
              ...prev,
              loadingUser: false,
              noUserFound: false,
              user: user.user,
              city: user.city || "",
            }));
          }
        })
        .catch(() => {
          window.toast("Something went wrong!");
          setStates((prev) => ({
            ...prev,
            loadingUser: false,
          }));
        });
    }
  }, [states.phoneNumber]);

  // Handle city dropdown changes
  const handleCityChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "other") {
      // Switch to "other" city mode
      setIsCityOther(true);
      // Optionally clear out city if you prefer
      setStates((prev) => ({ ...prev, city: "" }));
    } else {
      // Using a predefined city
      setIsCityOther(false);
      setStates((prev) => ({ ...prev, city: selectedValue }));
    }
  };

  // Handle occasion dropdown changes
  const handleOccasionChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "other") {
      setIsOccasionOther(true);
      // Optionally clear out occasion if you prefer
      setStates((prev) => ({ ...prev, occasion: "" }));
    } else {
      setIsOccasionOther(false);
      setStates((prev) => ({ ...prev, occasion: selectedValue }));
    }
  };

  return (
    <div className="fix-screen flex-center">
      {/* Overlay */}
      <div
        onClick={handleClose}
        className="fixed inset-0 bg-black bg-opacity-50 z-[1]"
      ></div>

      {/* Main Modal */}
      <div className="bg-white rounded-lg relative border text-black border-gray-200 flex-col z-[2] pt-[3rem] pb-[4rem] w-[30rem] max-w-[95dvw] max-h-[80dvh]">
        {/* Header */}
        <Header height="2.5rem" handleClose={handleClose} />

        {/* Form Section */}
        <div className="w-full flex-col px-2 space-y-2">
          {/* Phone Number + Loader */}
          <div className="relative mt-1">
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value={states.phoneNumber}
              onChange={(e) => {
                const value = e.target.value;
                // Allow only up to 10 digits
                if (/^\d{0,10}$/.test(value)) {
                  setStates((prevState) => ({
                    ...prevState,
                    phoneNumber: value,
                  }));
                }
              }}
              className="block w-full px-3 py-2 border text-sm border-gray-300 rounded-md shadow-sm
                         focus:outline-none focus:ring-[#2136d4] focus:border-[#2136d4]"
              placeholder="Enter 10-digit phone number"
            />
            {states.loadingUser && (
              <span
                className="loader w-[1rem] absolute right-3 top-1/2 
                           -translate-y-1/2"
              ></span>
            )}
          </div>

          {states.user && !states.noUserFound && (
            <div className="mt-3 space-y-1">
              <div className="text-sm flex-col ">
                <div className="text-sm text-gray-600 mb-1 font-medium ">
                  Customer:
                </div>
                <input
                  type="text"
                  value={states.user.name}
                  onChange={(e) =>
                    setStates((prev) => ({
                      ...prev,
                      user: {
                        ...prev.user,
                        name: e.target.value,
                      },
                    }))
                  }
                  className="  px-2 py-1 border text-sm border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#2136d4] focus:border-[#2136d4]"
                />
              </div>
            </div>
          )}
          {/* No User Found */}
          {states.noUserFound && (
            <div className="mt-2 space-y-2">
              <div className="text-xs text-red-500">
                No user found with this number
              </div>
              {/* Name Input */}
              <input
                type="text"
                value={states.user?.name}
                onChange={(e) =>
                  setStates((prev) => ({
                    ...prev,
                    user: {
                      ...prev.user,
                      name: e.target.value,
                    },
                  }))
                }
                placeholder="Customer Name"
                className="block w-full px-3 py-2 border text-sm border-gray-300 
                           rounded-md shadow-sm focus:outline-none 
                           focus:ring-[#2136d4] focus:border-[#2136d4]"
              />
              {/* Gender Selector */}
              <select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className="block w-full px-3 py-2 border text-sm border-gray-300 
                           rounded-md shadow-sm focus:outline-none 
                           focus:ring-[#2136d4] focus:border-[#2136d4]"
              >
                <option value="">-- Select Gender --</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
          )}
          {/* City Field (Always Present) */}
          <div className="mt-3">
            {!isCityOther ? (
              <>
                <select
                  id="city"
                  className="block w-full px-3 py-2 border text-sm border-gray-300 
                             rounded-md shadow-sm focus:outline-none 
                             focus:ring-[#2136d4] focus:border-[#2136d4]"
                  value={
                    // If states.city is not in the cityOptions list, fallback to "other"
                    cityOptions.some((c) => c.city === states.city)
                      ? states.city
                      : ""
                  }
                  onChange={handleCityChange}
                >
                  <option value="">-- Select City --</option>
                  {cityOptions.map((c) => (
                    <option key={c.city} value={c.city}>
                      {c.label}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              <div className="flex-1 relative flex items-center ">
                <input
                  id="cityOther"
                  type="text"
                  placeholder="Enter city name"
                  value={states.city}
                  onChange={(e) =>
                    setStates((prev) => ({ ...prev, city: e.target.value }))
                  }
                  className=" block w-full px-3 py-2 border text-sm border-gray-300 
                               rounded-md shadow-sm focus:outline-none 
                               focus:ring-[#2136d4] focus:border-[#2136d4]"
                />
                <button
                  type="button"
                  onClick={() => {
                    setIsCityOther(false);
                    setStates((prev) => ({ ...prev, city: "" }));
                  }}
                  className="text-sm bg-gray-100  absolute right-2 size-[1.5rem] flex-center rounded-full border"
                >
                  <IoMdClose />
                </button>
              </div>
            )}
          </div>

          {/* Occasion Field */}
          <div className="mt-3">
            {!isOccasionOther ? (
              <>
                <select
                  id="occasion"
                  className="block w-full px-3 py-2 border text-sm border-gray-300 
                             rounded-md shadow-sm focus:outline-none 
                             focus:ring-[#2136d4] focus:border-[#2136d4]"
                  value={
                    predefinedOccasions.some(
                      (oc) => oc.value === states.occasion
                    )
                      ? states.occasion
                      : ""
                  }
                  onChange={handleOccasionChange}
                >
                  <option value="">-- Select Occasion --</option>
                  {predefinedOccasions.map((oc) => (
                    <option key={oc.value} value={oc.value}>
                      {oc.label}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              <div className="flex-1 relative flex items-center ">
                <input
                  id="occasionOther"
                  type="text"
                  placeholder="Enter custom occasion"
                  value={states.occasion}
                  onChange={(e) =>
                    setStates((prev) => ({
                      ...prev,
                      occasion: e.target.value,
                    }))
                  }
                  className=" block w-full px-3 py-2 border text-sm border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#2136d4] focus:border-[#2136d4]"
                />
                <button
                  type="button"
                  onClick={() => {
                    setIsOccasionOther(false);
                    setStates((prev) => ({ ...prev, occasion: "" }));
                  }}
                  className="text-sm bg-gray-100  absolute right-2 size-[1.5rem] flex-center rounded-full border"
                >
                  <IoMdClose />
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Bottom Section (Save / Create / Etc.) */}
        <Bottom
          height="2.75rem"
          states={states}
          setStates={setStates}
          onCreateCallback={enquiryCreateModal?.options?.callback}
        />
      </div>
    </div>
  );
}
