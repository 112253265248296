import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { toast } from "react-toastify";
import { update_user_by_query_obj } from "../../../../../api/userApi";
export default function PersonalInfoEdit({
  parentsStates,
  setParentsStates,
  callback = (updatedUser) => {},
}) {
  return (
    <AnimatePresence>
      {parentsStates?.userPersonalInfoEdit && (
        <Modal
          setParentsStates={setParentsStates}
          parentsStates={parentsStates}
          callback={callback}
        />
      )}
    </AnimatePresence>
  );
}
const Modal = ({ parentsStates, setParentsStates, callback }) => {
  const handleClose = () =>
    setParentsStates((p) => ({
      ...p,
      userPersonalInfoEdit: false,
    }));

  const user = parentsStates.userInfo;
  const [states, setStates] = useState({
    isLoading: false,
    userType: user.userType,
    gender: user.gender,
    name: user.name,
    email: user.email,
    PPA: user.PPA,
    dateOfBirth: user.dateOfBirth,
    phoneNumber: user.phoneNumber,
    defaultAddress: user.defaultAddress,
  });

  const onNext = async () => {
    try {
      setStates((prev) => ({ ...prev, isLoading: true }));

      let updateObject = {
        userType: states.userType,
        gender: states.gender,
        name: states.name,
        email: states.email,
        dateOfBirth: states.dateOfBirth,
      };

      if (states.defaultAddress) {
        updateObject.defaultAddress = states.defaultAddress;
      }

      const res = await update_user_by_query_obj({
        queryObject: { _id: parentsStates.userInfo._id },
        updateObject,
      }); // Call the API function for creating a Santa
      if (res.isSuccess) {
        setParentsStates((p) => ({ ...p, userInfo: res.user }));
        callback(res.user);
        handleClose();
      } else {
        toast.error(res.message);
      }
      setStates((prev) => ({ ...prev, isLoading: false }));
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
  };

  return (
    <div
      className="fixed top-0 right-0 left-0 bottom-0
 flex items-end justify-end z-[16] "
    >
      <div
        onClick={handleClose}
        className="partial-black-background bg-[#000000a8]"
      ></div>
      <motion.div
        initial={{
          translateY: 800,
        }}
        animate={{
          translateY: 0,
        }}
        exit={{
          translateY: 800,
        }}
        transition={{ ease: "easeInOut" }}
        style={{
          height: "calc(100vh - 12rem)",
        }}
        className="w-full flex items-center rounded-t-xl overflow-hidden drop-shadow border-t px-4 bg-[#ffffff] z-[2] text-[.875rem] flex-col "
      >
        {states.isLoading && (
          <div className="absolute text-white gap-2 top-0 right-0 left-0 bottom-0 flex items-center justify-center bg-[#000000a5] z-[1]">
            <div
              style={{ borderTopColor: "white" }}
              className="loader w-[1rem]"
            ></div>
            Saving...
          </div>
        )}
        <div className="w-full font-[500] flex items-center justify-between text-[1rem] mb-2 py-2 ">
          <div onClick={handleClose} className="text-[#2136d4] cursor-pointer">
            Cancel
          </div>
          <div className=" flex flex-col justify-center items-center ">
            <div className="text-[1.125rem] font-[500] ">User info edit</div>
          </div>
          <div onClick={onNext} className="text-[#2136d4] cursor-pointer">
            Save
          </div>
        </div>

        <div className="flex flex-col md:flex-row h-full overflow-scroll gap-6 w-full">
          <div className="w-full">
            <p className="text-[1rem] mb-[2px]">Personal Details </p>
            <div
              className="rounded-xl overflow-scroll  p-2
          relative flex flex-col bg-[#f5f5f5] w-full  "
            >
              <div className="text-[.75rem] text-[gray] px-1">Name</div>
              <input
                value={states.name}
                onChange={(e) => {
                  setStates((p) => ({ ...p, name: e.target.value }));
                }}
                placeholder="user name"
                className="input2 mb-2"
              />
              <div className="text-[.75rem] text-[gray] px-1">Phone number</div>
              <input
                value={states.phoneNumber}
                onChange={(e) => {
                  setStates((p) => ({ ...p, phoneNumber: e.target.value }));
                }}
                type="number"
                placeholder="phone number"
                className="input2 mb-2"
              />
              <div className="text-[.75rem] text-[gray] px-1">Email</div>
              <input
                value={states.email}
                onChange={(e) => {
                  setStates((p) => ({ ...p, email: e.target.value }));
                }}
                placeholder="email"
                className="input2 mb-2"
              />
              <div className="text-[.75rem] text-[gray] px-1">Gender</div>
              <select
                value={states.gender}
                onChange={(e) => {
                  setStates((p) => ({ ...p, gender: e.target.value }));
                }}
                className="input2 mb-2"
              >
                <option value={""}>Not selected</option>
                <option value={"male"}>Male</option>
                <option value={"female"}>Female</option>
                <option value={"other"}>Other</option>
              </select>
              <div className="text-[.75rem] text-[gray] px-1">DOB</div>
              <input
                value={states.dateOfBirth}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length > 10) return;
                  if (value.length > states.dateOfBirth.length) {
                    if (
                      states.dateOfBirth.length === 2 ||
                      states.dateOfBirth.length === 5
                    ) {
                      setStates((p) => ({
                        ...p,
                        dateOfBirth:
                          p.dateOfBirth + "-" + value.charAt(value.length - 1),
                      }));
                    } else {
                      setStates((p) => ({
                        ...p,
                        dateOfBirth: value,
                      }));
                    }
                  } else {
                    setStates((p) => ({
                      ...p,
                      dateOfBirth: value,
                    }));
                  }
                }}
                placeholder="DD-MM-YYYY"
                className="input2 mb-2"
              />
              <div className="text-[.75rem] text-[gray] px-1">PPA</div>
              <input
                value={states.PPA}
                onChange={(e) => {
                  setStates((p) => ({ ...p, PPA: e.target.value }));
                }}
                placeholder="user PPA"
                className="input2"
              />
            </div>
          </div>
          <div className="w-full">
            <p className="text-[1rem] mb-[2px]"> Default address section </p>
            <div className=" rounded-xl bg-[#f5f5f5] w-full p-2">
              <div className="text-[.75rem] text-[gray] px-1">State</div>
              <select
                value={states.defaultAddress?.state}
                onChange={(e) =>
                  setStates((p) => ({
                    ...states,
                    defaultAddress: {
                      ...p.defaultAddress,
                      state: e.target.value,
                    },
                  }))
                }
                className="input2 mb-2"
              >
                <option value="">Select State</option>
                {defaultStates.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              <div className="text-[.75rem] text-[gray] px-1">City</div>
              <input
                value={states.defaultAddress?.city}
                onChange={(e) =>
                  setStates((p) => ({
                    ...states,
                    defaultAddress: {
                      ...p.defaultAddress,
                      city: e.target.value,
                    },
                  }))
                }
                placeholder="City"
                className="input2 mb-2"
              />
              {/* <div className="text-[.75rem] text-[gray] px-1">Pincode</div>
            <input
              value={states.defaultAddress?.pincode}
              onChange={(e) =>
                setStates((p) => ({
                  ...states,
                  defaultAddress: {
                    ...p.defaultAddress,
                    pincode: e.target.value,
                  },
                }))
              }
              placeholder="Pincode"
              className="input2 mb-2"
            /> */}
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

const defaultStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];
