import React, { useState } from "react";
import { FaClock } from "react-icons/fa";
import TimePickerModal from "./TimePickerModal";

export default function ReadyTimeField({ states, setStates }) {
  const [showTimeModal, setShowTimeModal] = useState(false);

  return (
    <div className="relative flex-1">
      <FaClock className="absolute left-3 top-3 text-gray-500 text-sm" />
      <input
        type="text"
        readOnly
        className="border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md px-8 py-2 text-sm w-full shadow-sm transition cursor-pointer"
        placeholder="Ready by"
        value={states.readyTime || ""}
        onClick={() => setShowTimeModal(true)}
      />

      {showTimeModal && (
        <TimePickerModal
          onClose={() => setShowTimeModal(false)}
          onSelectTime={(time) => {
            setStates((prev) => ({ ...prev, readyTime: time }));
            setShowTimeModal(false);
          }}
        />
      )}
    </div>
  );
}
