import React, { useContext, useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import TextSearchBar from "./c/TextSearchBar";
import ServiceAvailable from "./c/ServiceAvailable";
import DefaultLocationSuggestions from "./c/DefaultLocationSuggestions";
import Map from "./c/Map";
import StoreContext from "../../../context/StoreContext";
import { RxCross2 } from "react-icons/rx";
import {
  decimalToDMS,
  dmsToDecimal,
} from "../../../functions/helperFunctions/helperFunctions";

let queryDebounceTime = 1000;
let autocompleteService;
let country = "IN";
let timeoutId;
let autoCompleteRef = null;
let mapContainerRef = null;
let minSearchTextLengthToStartSearch = 4;

export default function AddressSelect({ callback, selectedAddress, onClose }) {
  const [states, setStates] = useState({
    lat: selectedAddress?.lat || 20,
    lng: selectedAddress?.lng || 82,
    map: null,
    marker: null,
    address: "",
    placeId: "",
    predictions: [],
    query: "",
    defaultUi: selectedAddress?.lat ? false : true,
    isChangeMapPosition: false,
    mapScriptLoaded: false,
    searchBarResultAddress: "",
    loadingCurrentLocation: false,
    isPredictionsShow: false,
    loadingPredictions: false,
    loadingPointLocation: false,
    showServiceCites: selectedAddress?.lat ? false : true,
    loadingReverseGeoCode: false,
    showMap: selectedAddress?.lat ? true : false,
    serviceAvailable: false,
    noServiceAvailable: false,
    loadingLocation: false,
    searchUsingCoordinates: false,
    newLat: "",
    newLng: "",
    combinedDMS: "",
  });

  const { storeStates, setStoreStates } = useContext(StoreContext);

  useEffect(() => {
    // Check if the script is already loaded
    if (!storeStates.isGoogleMapsScriptLoaded) {
      // Load the Google Maps API script
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`;
      script.defer = true;
      document.head.appendChild(script);

      // Set the global variable to true once the script is loaded
      script.onload = () => {
        setStoreStates((p) => ({
          ...p,
          isGoogleMapsScriptLoaded: true,
        }));
        initMap();
      };

      // Cleanup to prevent memory leaks
      return () => {
        document.head.removeChild(script);
      };
    } else {
      // The script is already loaded, perform any necessary actions
      initMap();
    }

    // eslint-disable-next-line
  }, []);

  const initMap = () => {
    setStates((p) => ({ ...p, mapScriptLoaded: true }));
    // initializing the autocomplete service for getting predictions
    autocompleteService = new window.google.maps.places.AutocompleteService();
  };

  const handleClose = () => {
    if (!states.defaultUi) {
      setStates((p) => ({
        ...p,
        defaultUi: true,
        loadingCurrentLocation: false,
        isPredictionsShow: false,
        loadingPredictions: false,
        loadingPointLocation: false,
        showServiceCites: true,
        loadingReverseGeoCode: false,
        serviceAvailable: false,
        noServiceAvailable: false,
        loadingLocation: false,
      }));
    } else {
      onClose();
    }
  };

  const handleConfirmLocation = () => {
    try {
      const address1 =
        states.searchBarResultAddress?.split(",")[0] ||
        states.premise ||
        states.streetName ||
        states.route ||
        states.s_locality1 ||
        states.s_locality2 ||
        states.s_locality3 ||
        states.city ||
        states.pincode;

      const selectedLocation = {
        lat: states.lat,
        lng: states.lng,
        address1,
        city: states.city,
        state: states.state,
        pincode: states.pincode,
        address2: states.searchBarResultAddress || states.formatedAddress,
      };

      callback(selectedLocation);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDone = () => {
    setStates((p) => ({
      ...p,
      lat: parseFloat(states.newLat),
      lng: parseFloat(states.newLng),
      isChangeMapPosition: true,
      showMap: true,
      defaultUi: false,
    }));
  };

  const handleLatChange = (e) => {
    const newLat = e.target.value;
    let newCombinedDMS = "";

    try {
      const latDecimal = dmsToDecimal(newLat);
      const lngDecimal = dmsToDecimal(states.newLng);

      newCombinedDMS = `${decimalToDMS(latDecimal, true)} + ${decimalToDMS(
        lngDecimal,
        false
      )}`;
    } catch {
      newCombinedDMS = "";
    }

    setStates((p) => ({
      ...p,
      newLat,
      combinedDMS: newCombinedDMS,
    }));
  };

  const handleLngChange = (e) => {
    const newLng = e.target.value;
    let newCombinedDMS = "";

    try {
      const latDecimal = dmsToDecimal(states.newLat);
      const lngDecimal = dmsToDecimal(newLng);

      newCombinedDMS = `${decimalToDMS(latDecimal, true)} + ${decimalToDMS(
        lngDecimal,
        false
      )}`;
    } catch {
      newCombinedDMS = "";
    }

    setStates((p) => ({
      ...p,
      newLng,
      combinedDMS: newCombinedDMS,
    }));
  };

  return (
    <div className="w-full h-full text-sm flex-col items-center bg-[white] z-[1] relative">
      <div className="flex w-full gap-2 items-center bg-[white] p-4 pb-2 pt-3 text-[1.125rem] font-[500] ">
        <FaAngleLeft onClick={handleClose} className="text-[1.25rem]" />
        <div>Select service location</div>
      </div>
      <div className="w-full mb-2 pb-2 bg-white">
        {states.searchUsingCoordinates ? (
          <>
            {/* Input Fields */}
            <div className="flex gap-4 w-full px-3 items-center">
              <input
                type="text"
                name="lat"
                value={states.newLat}
                onChange={handleLatChange}
                placeholder="Latitude (e.g., 24.7856 or 24°46'23.8\N)"
                className="border w-full rounded-md text-[1rem] px-3 py-2 outline-none focus:ring-2 focus:ring-blue-500 transition"
              />
              <input
                type="text"
                name="lng"
                value={states.newLng}
                onChange={handleLngChange}
                placeholder="Longitude (e.g., 85.1234 or 85°00'23.5\E)"
                className="border w-full rounded-md text-[1rem] px-3 py-2 outline-none focus:ring-2 focus:ring-blue-500 transition"
              />
            </div>

            {/* Buttons */}
            <div className="flex justify-end mt-3 gap-3 w-full px-3 items-center">
              <button
                onClick={handleDone}
                className="bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-md px-4 py-2 flex items-center justify-center transition-all duration-300"
              >
                Find Location
              </button>
              <RxCross2
                className="text-red-500 text-[1.75rem] cursor-pointer hover:text-red-700 transition"
                onClick={() =>
                  setStates((p) => ({
                    ...p,
                    searchUsingCoordinates: false,
                  }))
                }
              />
            </div>
          </>
        ) : (
          <div
            onClick={() =>
              setStates((p) => ({
                ...p,
                searchUsingCoordinates: !p.searchUsingCoordinates,
              }))
            }
            className="px-4 text-[1rem] text-gray-600 w-full flex justify-end items-center gap-2 cursor-pointer hover:text-gray-800 transition"
          >
            <span>Select using coordinates</span>
            <input
              type="checkbox"
              checked={states.searchUsingCoordinates}
              onChange={(e) =>
                setStates((p) => ({
                  ...p,
                  searchUsingCoordinates: e.target.checked,
                }))
              }
              className="cursor-pointer"
            />
          </div>
        )}
      </div>
      {/* location search bar (position: absolute)  */}
      <TextSearchBar
        minSearchTextLengthToStartSearch={minSearchTextLengthToStartSearch}
        states={states}
        setStates={setStates}
        timeoutId={timeoutId}
        queryDebounceTime={queryDebounceTime}
        autocompleteService={autocompleteService}
        country={country}
      />

      {/* the default initial ui (including the prediction search list) */}
      <DefaultLocationSuggestions states={states} setStates={setStates} />

      {/* if the selected location on the map is serviceable */}
      <ServiceAvailable
        states={states}
        handleConfirmLocation={handleConfirmLocation}
        setStates={setStates}
        autoCompleteRef={autoCompleteRef}
      />

      {/* the main google map */}
      {states.showMap && (
        <Map
          states={states}
          setStates={setStates}
          mapContainerRef={mapContainerRef}
        />
      )}
    </div>
  );
}
