import React from "react";
import { FaUserAlt, FaExternalLinkAlt } from "react-icons/fa";

export default function UserHeader({ userId }) {
  if (!userId || !userId.name) return null;

  return (
    <div className="flex items-center justify-between p-3 bg-white rounded-lg shadow-sm border border-gray-200">
      {/* Left: User Info */}
      <div className="flex items-center gap-3">
        <div className="p-2 bg-gray-100 rounded-full">
          <FaUserAlt className="text-gray-600 w-4 h-4" />
        </div>
        <div className="flex flex-col text-sm">
          <span className="font-semibold text-gray-800">{userId.name}</span>
          <div className="flex items-center gap-1 text-gray-600 text-xs">
            <span>{userId.phoneNumber}</span>
          </div>
        </div>
      </div>

      {/* Right: Actions (Navigate to user details) */}
      <button
        onClick={() => {
          window.open(`/user/profile/${userId._id}`, "_blank");
        }}
        className="text-gray-500 hover:text-blue-600 transition p-2"
        title="View User Details"
      >
        <FaExternalLinkAlt className="text-sm" />
      </button>
    </div>
  );
}
