import React, { useState } from "react";
import { FaUserShield } from "react-icons/fa";
import ExpertSelectModal from "./ExpertSelectModal";

/**
 * Updated ExpertAssignmentModal:
 * - "Done" button to close the modal.
 * - "Reassign Expert" button on the left.
 * - If no experts, a full-width "Assign Expert" button is displayed.
 */
export default function ExpertAssignmentModal({
  onClose,
  enquiryId,
  assignedExperts,
  admins,
  onUpdateAssignedExperts,
}) {
  const [showSelectModal, setShowSelectModal] = useState(false);

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-40 backdrop-blur-md"
    >
      {/* Modal Panel */}
      <div
        onClick={(e) => e.stopPropagation()}
        className="relative z-10 bg-white w-[400px] rounded-lg shadow-lg"
      >
        {/* Header */}
        <div className="p-4 border-b flex justify-between items-center">
          <div className="flex items-center space-x-2 text-lg font-semibold text-gray-800">
            <FaUserShield className="text-blue-600" />
            <span>Assigned Experts</span>
          </div>
          <button
            className="text-gray-500 hover:text-gray-700 transition"
            onClick={onClose}
          >
            ✕
          </button>
        </div>

        {/* Assigned Experts List */}
        <div className="p-4 space-y-2 max-h-[300px] overflow-y-auto">
          {assignedExperts?.length > 0 ? (
            assignedExperts.map((exp, idx) => {
              const isCurrent = idx === assignedExperts.length - 1;
              return (
                <AssignedExpertRow
                  key={exp._id || idx}
                  assignedExpert={exp}
                  isCurrent={isCurrent}
                />
              );
            })
          ) : (
            <div className="py-6 text-sm text-gray-500 text-center">
              No experts assigned yet
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="p-4 border-t flex justify-between">
          {assignedExperts.length > 0 ? (
            <>
              <button
                onClick={() => setShowSelectModal(true)}
                className="bg-gray-200 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-300 transition"
              >
                Reassign Expert
              </button>
              <button
                onClick={onClose}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
              >
                Done
              </button>
            </>
          ) : (
            <button
              onClick={() => setShowSelectModal(true)}
              className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
            >
              Assign Expert
            </button>
          )}
        </div>
      </div>

      {/* Expert Selection Modal */}
      {showSelectModal && (
        <ExpertSelectModal
          onClose={() => setShowSelectModal(false)}
          enquiryId={enquiryId}
          assignedExperts={assignedExperts}
          admins={admins}
          onUpdateAssignedExperts={onUpdateAssignedExperts}
        />
      )}
    </div>
  );
}

/** Improved Assigned Expert Row */
function AssignedExpertRow({ assignedExpert, isCurrent }) {
  const { expert, date } = assignedExpert;
  if (!expert) return null;

  const assignmentDate = date
    ? new Date(date).toLocaleString()
    : "No date info";

  return (
    <div
      className={`p-3 flex items-center justify-between rounded-lg transition ${
        isCurrent
          ? "bg-blue-50 border border-blue-500 shadow-sm"
          : "hover:bg-gray-100"
      }`}
    >
      <div>
        <p className="font-medium text-gray-800">{expert.userName}</p>
        <p className="text-xs text-gray-500">{expert.mobile}</p>
      </div>
      <div className="text-xs text-gray-400">{assignmentDate}</div>
      {isCurrent && (
        <span className="ml-2 text-xs font-semibold text-blue-600">
          Current
        </span>
      )}
    </div>
  );
}
