import React, { useState } from "react";
import { FaUserEdit, FaUserShield } from "react-icons/fa";
import ExpertAssignmentModal from "./ExpertAssignmentModal";

/**
 * The small button/icon in the Enquiry Edit Modal (top-right corner).
 * Clicking it opens ExpertAssignmentModal to show assigned experts.
 */
export default function ExpertSwitch({
  enquiryId,
  assignedExperts,
  admins,
  onUpdateAssignedExperts,
}) {
  const [showModal, setShowModal] = useState(false);

  const currentExpertId =
    assignedExperts?.length > 0
      ? assignedExperts[assignedExperts.length - 1].expert?._id
      : null;

  const currentExpert =
    admins.find((adm) => adm._id === currentExpertId) || null;

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="flex items-center gap-2 px-3 py-1.5 bg-gray-100 text-gray-800 text-sm rounded-lg shadow-sm hover:bg-gray-200 transition"
      >
        <FaUserShield className="text-blue-600" />
        <span>{currentExpert?.userName || "Assign Expert"}</span>
        <FaUserEdit className="text-gray-500 hover:text-gray-700 transition" />
      </button>

      {showModal && (
        <ExpertAssignmentModal
          onClose={() => setShowModal(false)}
          enquiryId={enquiryId}
          assignedExperts={assignedExperts}
          admins={admins}
          onUpdateAssignedExperts={onUpdateAssignedExperts}
        />
      )}
    </>
  );
}
