import React from "react";
import { RxCross1 } from "react-icons/rx";

export default function Header({ handleClose, height }) {
  return (
    <div
      className="w-full absolute top-0 left-0 right-0 border-b border-gray-200 flex items-center justify-between px-4 bg-white shadow-sm"
      style={{
        height,
      }}
    >
      {/* Title */}
      <h1 className="text-lg font-semibold text-gray-800">Edit Enquiry</h1>

      {/* Close Button */}
      <button
        onClick={handleClose}
        className="ml-auto text-gray-500 hover:text-gray-800 transition"
      >
        <RxCross1 size={20} />
      </button>
    </div>
  );
}
